import {
  EditOutlined,
  RollbackOutlined,
  QuestionCircleFilled,
  SaveOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";
import { Wind } from "iconsax-react";
import {
  Input,
  Col,
  Row,
  Descriptions,
  Collapse,
  Typography,
  Modal,
  Select,
  Tooltip
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import { lang, COMMON_CONSTANT, KEY } from "../../constants/common.const";
import ApiAirWashing from "@src/api/backend/airWashing";
import {
  MSG_CATCH,
  showMessage,
  getQuerySearch
} from "../../constants/utils";
import BoxContent from "@src/component/Desktop/content";
import "./machine.scss";
import { isArray } from "lodash";
import { LANG } from "../../constants/lang.const";
import FooterPage from "@src/component/footer/footerPage";
import { isEmpty } from "lodash";
import { UploadFileSpace } from './view';
import ApiMachine from "../../api/backend/machineManagement";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
const { Text } = Typography;

function ViewMachine({ isEndUser }) {
  const history = useHistory();
  const { id } = useParams();
  const { version } = getQuerySearch();
  const isUpdate = id && history.location.pathname.includes("update");
  const isView = id && !history.location.pathname.includes("update");
  const isCreate = !id && history.location.pathname.includes("create");
  const { isLeader } = useSelector((state) => state.auth);

  const [showLoading, setShowLoading] = useState(false);
  const [machineData, setMachineData] = useState({
    "modelNumber": '',
    "model": '',
    "airVolume": '',
    "dischargePress": '',
    "motorPower": '',
    "materialCasing": '',
    "materialRotor": '',
    "partition": '',
    "weight": '',
    "soundProofBoxId": '',
    "priceId": '',
  });
  useEffect(() => {
    if (isView || isUpdate) {
      setShowLoading(true);
      ApiAirWashing.getOne(id, version == 'update' ? '' : version)
        .then((res) => {
          if (res.status === 200) {
            res.data.soundProofBoxModel = res.data.soundProofBox ? res.data.soundProofBox.model : '-';
            res.data.airWashingBlowerModelPrice = res.data.airWashingBlowerPrice ? res.data.airWashingBlowerPrice.airWashingBlowerModelPrice : 0;
            res.data.v400OutdoorPrice = res.data.electricMotorPrice ? res.data.electricMotorPrice.v400OutdoorPrice : 0;
            setMachineData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, [id]);

  const [soundProofBox, setSoundProofBox] = useState([]);
  const [airWashingBlower, setAirWashingBlower] = useState([]);
  const [electricMotor, setElectricMotor] = useState([]);
  useEffect(() => {
    if (isCreate || isUpdate) {
      setShowLoading(true);
      Promise.all([
        ApiAirWashing.getSoundProofBox(),
        ApiAirWashing.getAirWashingBlower(),
        ApiAirWashing.getElectricMotor(),
      ])
        .then((res) => {
          if (res[0].status === 200) {
            setSoundProofBox(res[0].data.data);
          }
          if (res[1].status === 200) {
            setAirWashingBlower(res[1].data.data);
          }
          if (res[2].status === 200) {
            setElectricMotor(res[2].data.data.sort((a, b) => a.electricMotorPower - b.electricMotorPower));
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, []);

  const save = async () => {
    const body = {
      "id": machineData.id,
      "modelNumber": machineData.modelNumber,
      "model": machineData.model,
      "airVolume": +machineData.airVolume,
      "dischargePress": +machineData.dischargePress,
      "motorPower": machineData.motorPower,
      "materialCasing": machineData.materialCasing,
      "materialRotor": machineData.materialRotor,
      "partition": +machineData.partition,
      "weight": +machineData.weight,
      "soundProofBoxId": machineData.soundProofBoxId,
      "soundProofBoxModel": machineData.soundProofBoxModel,
      "priceId": 1,
      "attachFiles": listAttachMachine.map((item, index) => {
        return {
          "fileName": item.fileName,
          "fileUrl": item.fileUrl,
          "order": index,
          "note": item.note,
          "recordVersion": 0,
        }
      }),
    }

    const api = isCreate ? ApiAirWashing.save(body) : ApiAirWashing.update(body);
    try {
      setShowLoading(true);
      const res = await api;
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      history.push(`/air/${body.modelNumber}`);
    } catch (e) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };

  const handleSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.BOM_C009 : COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => { save() },
    });
  };

  const [listAttachMachine, setListAttachMachine] = useState([]);
  const downloadFile = async () => {
    try {
      setShowLoading(true);
      const thisType = 3;
      const resApi = ApiMachine.downloadFile(machineData.modelNumber || "", thisType, '', version);
      const res = await resApi;
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = `${machineData.modelNumber || 'file'}.zip`;
      link.click();
    } catch (e) {
      let msg = MSG_CATCH();
      if (e.response && e.response.status == 404) {
        msg = "ファイルが見つかりません";
      }
      showMessage(KEY.ERROR, msg);
    } finally {
      setShowLoading(false);
    };
  };

  return (
    <>
      <div className="des-content machine-content">
        <TitlePage name={isView ? LANG.VIEW_MACHINE : (id ? LANG.UPDATE_MACHINE : LANG.ADD_MACHINE)} />
        <Loading show={showLoading}></Loading>
        <Row gutter={0} style={{}}>
          <Col span={24} className="mt8">
            <DetailMachine
              machineData={machineData}
              setMachineData={setMachineData}
              soundProofBox={soundProofBox}
              airWashingBlower={airWashingBlower}
              electricMotor={electricMotor}
            />
          </Col>
          <Col span={24} className="mt8">
            <UploadFileSpace listAttachMachine={listAttachMachine} setListAttachMachine={setListAttachMachine}
              isView={isView} setShowLoading={setShowLoading} type={3}
              version={version} id={id}
            />
          </Col>
        </Row>
        <div style={{ height: "100px" }}></div>
      </div>
      <FooterPage>
        <Row style={{ cursor: 'pointer' }}>
          {isView && <Col span={isLeader ? 8 : 12}>
            <div
              className={isLeader ? "actionRight" : "actionLeft"}
              style={{ borderRight: "1px solid #e8e8e8" }}
              onClick={() => downloadFile()}
            >
              <CloudDownloadOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {lang.DOWNLOAD_PDF}
            </div>
          </Col>}
          <Col span={isView && isLeader ? 8 : 12}>
            <div
              className={"actionCancel"}
              style={{ borderRight: "1px solid #e8e8e8" }}
              onClick={() => history.push(`/machine/list?tab=3`)}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          {isView && isLeader && <Col span={8}>
            <div
              onClick={() => history.push(`/air/${id}/update`)}
              className={"actionLeft"}
            >
              <EditOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              {lang.EDIT}
            </div>
          </Col>}
          {!isView && <Col span={12}>
            <div
              onClick={handleSaveData}
              className={"actionLeft"}
            >
              <SaveOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              {lang.SAVE}
            </div>
          </Col>}
        </Row>
      </FooterPage>
    </>
  );
}

export default ViewMachine;

export const DetailMachine = ({ id: idProp, viewMode = false, machineData, setMachineData, soundProofBox = [], airWashingBlower = [], electricMotor = [], version }) => {
  const history = useHistory();
  let { id } = useParams();
  id = idProp || id;
  const isUpdate = !viewMode && id && history.location.pathname.includes("update");
  const isView = viewMode || (id && !history.location.pathname.includes("update"));
  const isCreate = !viewMode && !id && history.location.pathname.includes("create");

  const [showLoading, setShowLoading] = useState(false);

  const machine_information_display = [
    {
      key: 'modelNumber',
      title: "機種番号",
      content: machineData.modelNumber,
      isRequired: true,
      auto: true,
    },
    {
      key: 'airVolume',
      title: (<>空気量 <br />(N㎥/h)</>),
      content: machineData.airVolume,
      isRequired: true,
      inputType: 'number',
    },
    {
      key: 'dischargePress',
      title: (<>吐出圧力<br />(kPa)</>),
      content: machineData.dischargePress,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
    },
    {
      title: (<>電動機</>),
      content: [
        {
          key: 'motorPower',
          title: (<>kW</>),
          content: machineData.motorPower,
          inlineDisplay: true,
          isSelect: true,
          options: electricMotor.map((item) => {
            return {
              value: item.electricMotorPower,
              label: item.electricMotorPower,
            };
          }),
          isRequired: true,
        },
        {
          title: "基準単価(千円)",
          content: machineData.electricMotorPrice ? machineData.electricMotorPrice.v400OutdoorPrice : 0,
          auto: true,
        },
      ],
    },
    {
      title: "材質",
      content: [
        {
          key: 'materialCasing',
          title: "ケーシング",
          content: machineData.materialCasing,
          isRequired: true,
        },
        {
          key: 'materialRotor',
          title: "ロータ",
          content: machineData.materialRotor,
          isRequired: true,
        },
      ],
      inlineDisplay: true,
    },
    {
      title: 'ブロワ',
      content: [
        {
          key: 'model',
          title: <>型式</>,
          content: machineData.model,
          inlineDisplay: true,
          isSelect: true,
          options: airWashingBlower.map((item) => {
            return {
              value: item.model,
              label: item.model,
            };
          }),
          isRequired: true,
        },
        {
          title: "ブロワ価格（千円）",
          content: machineData.airWashingBlowerPrice ? machineData.airWashingBlowerPrice.airWashingBlowerModelPrice : 0,
          auto: true,
        },
      ]
    },
    {
      title: (<>防音BOX</>),
      content: [
        {
          key: 'soundProofBoxModel',
          title: "型式",
          content: machineData.soundProofBoxModel,
          isSelect: true,
          options: soundProofBox.map((item) => {
            return {
              value: item.model,
              label: item.model,
            };
          }),
          isRequired: true,
        },
        {
          title: "重量(kg)",
          content: machineData.soundProofBox ? machineData.soundProofBox.weight : 0,
          auto: true,
        },
        {
          title: "仕切",
          content: machineData.soundProofBox ? machineData.soundProofBox.partition : 0,
          auto: true,
        },
        {
          title: "基準単価(千円)",
          content: machineData.soundProofBox ? machineData.soundProofBox.price : 0,
          auto: true,
        },
        {
          title: <><QuestionToolTip title={"仕切 x 基準単価"} /> 価格(千円)</>,
          content: <>{machineData.soundProofBox
            ? Math.ceil(+machineData.soundProofBox.price * +machineData.soundProofBox.partition)
            : 0
          }</>,
          auto: true,
        },
      ],
      inlineDisplay: true,
    },
    {
      title: (<><QuestionToolTip title={"空気量(N㎥/h) x 吐出圧力(kPa) x 電動機(kW)"} /> 能力</>),
      content: (machineData.airVolume && machineData.dischargePress && machineData.motorPower) ? `${machineData.airVolume}N㎥/h x ${machineData.dischargePress}kPa x ${machineData.motorPower}kW` : '-',
      inlineDisplay: true,
      auto: true
    },
    {
      key: 'weight',
      title: (<>重量<br />(kg)</>),
      content: machineData.weight,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
    },
    {
      key: 'partition',
      title: <>仕切</>,
      content: machineData.partition,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
    },
    {
      title: (<><QuestionToolTip title="電動機 + 機種" /> 基準単価<br />(千円)</>),
      content: (machineData.airWashingBlowerPrice && machineData.electricMotorPrice)
        ? (machineData.airWashingBlowerPrice.airWashingBlowerModelPrice + machineData.electricMotorPrice.v400OutdoorPrice)
        : 0,
      auto: true,
      inlineDisplay: true,
    },
    {
      key: '?',
      title: <><QuestionToolTip title="仕切 x (電動機 + 機種)" /> 価格<br />(千円)</>,
      content: <>
        {
          (machineData.airWashingBlowerPrice && machineData.electricMotorPrice && machineData.partition)
            ? Math.ceil(machineData.partition * (machineData.airWashingBlowerPrice.airWashingBlowerModelPrice + machineData.electricMotorPrice.v400OutdoorPrice))
            : 0
        }
      </>,
      auto: true,
      inlineDisplay: true,
    },
  ];

  useEffect(() => {
    if (isEmpty(electricMotor) || !machineData.motorPower) return;
    const selected = electricMotor.find((item) => item.electricMotorPower === machineData.motorPower);
    const newMachineData = { ...machineData };
    newMachineData.electricMotorPrice = selected;
    setMachineData(newMachineData);
  }, [machineData.motorPower, electricMotor]);

  useEffect(() => {
    if (isEmpty(airWashingBlower) || !machineData.model) return;
    const selected = airWashingBlower.find((item) => item.model === machineData.model);
    const newMachineData = { ...machineData };
    newMachineData.airWashingBlowerPrice = selected;
    setMachineData(newMachineData);
  }, [machineData.model, airWashingBlower]);

  useEffect(() => {
    if (isEmpty(soundProofBox) || !machineData.soundProofBoxModel) return;
    const selected = soundProofBox.find((item) => item.model === machineData.soundProofBoxModel);
    const newMachineData = { ...machineData };
    newMachineData.soundProofBox = selected;
    newMachineData.soundProofBoxId = selected.id;
    setMachineData(newMachineData);
  }, [machineData.soundProofBoxModel, soundProofBox]);

  const onChangeInput = (e, info) => {
    let { value } = e.target;
    const newmMchineData = { ...machineData };

    // remove character not numeric when inputType is number
    if (info.inputType === "number") {
      value = value.replace(/[^0-9.]/g, "");
      if (value.split(".").length > 2) {
        value = value.substring(0, value.length - 1);
      }
    }

    newmMchineData[info.key] = value;
    setMachineData(newmMchineData);
  };

  const onChangeSelect = (value, info) => {
    const newmMchineData = { ...machineData };
    newmMchineData[info.key] = value;
    setMachineData(newmMchineData);
  };

  const Content = <Descriptions size="small" column={1} bordered>
    {machine_information_display.map((info, index) => {
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={<>{info.title} {(info.isRequired && !isView) && <span className="mark-required">*</span>}</>}
          labelStyle={{
            width: "28%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: !isArray(info.content) ? "8px" : "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!isArray(info.content) && isView &&
            (info.content || "-")}
          {!isArray(info.content) && !isView && (<>
            {
              !info.isSelect && !info.auto && <Input
                // type={info.inputType || 'text'}
                value={info.content}
                onChange={(e) => { onChangeInput(e, info) }}
              />
            }
            {
              info.isSelect && <Select className="w100" options={info.options} value={info.content} onChange={(e) => onChangeSelect(e, info)}
              // type={info.inputType || 'text'}
              />
            }
            {
              info.auto && <Text>{info.content}</Text>
            }
          </>
          )}
          {isArray(info.content) && (
            <Descriptions
              key={index + '1'}
              size="small"
              column={1}
              bordered
            >
              {info.content.map((info, index) => {
                return (
                  <Descriptions.Item
                    key={index}
                    span={1}
                    label={<>{info.title} {(info.isRequired && !isView) && <span className="mark-required">*</span>}</>}
                    labelStyle={{
                      width: "45%",
                      fontWeight: "semi-bold",
                    }}
                  >
                    {isView && (info.content || "-")}
                    {!isView && <>
                      {
                        !info.isSelect && !info.auto && <Input value={info.content} onChange={(e) => { onChangeInput(e, info) }} />
                      }
                      {
                        info.isSelect && <Select className="w100" options={info.options} value={info.content} onChange={(e) => onChangeSelect(e, info)} />
                      }
                      {
                        info.auto && <Text>{info.content}</Text>
                      }
                    </>}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
        </Descriptions.Item>
      );
    })}
  </Descriptions>

  const [listAttachMachine, setListAttachMachine] = useState([]);
  if (viewMode) {
    return <>
      <Loading show={showLoading} />
      {Content}
      <div className="mt8 machine-content">
        <UploadFileSpace listAttachMachine={listAttachMachine} setListAttachMachine={setListAttachMachine}
          isView={isView} setShowLoading={() => { }} type={3}
          version={version || 0} id={machineData.modelNumber} onlyTable={true}
        />
      </div>
    </>
  }

  return <>
    <Loading show={showLoading} />
    <BoxContent style={{ margin: 0, padding: 0 }}>
      <Collapse
        defaultActiveKey={["1"]}
        expandIconPosition="right"
      >
        <Panel header={<><Wind className="first-icon" />空洗ブロワ機種表</>} key="1">
          {Content}
        </Panel>
      </Collapse>
    </BoxContent></>
};

export const QuestionToolTip = ({ title }) => {
  return <Tooltip title={title}>
    <QuestionCircleFilled className="cursor-pointer" style={{ fontSize: 16 }} />
  </Tooltip>
}