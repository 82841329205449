import { KEY as KEY1 } from "@src/constants/common.const";
import React, { useRef, useState, version } from "react";
import { LANG } from "../../../constants/lang.const";
import { useMediaQuery } from "react-responsive";

import { SaveOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Input, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { EstimateApi } from "../../../api/backend/estimate";
import { COMMON_CONSTANT, STYLE, lang } from "../../../constants/common.const";
import _ from "lodash";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import { closeLoading, closeSave, openLoading } from "../../../redux/estimate";

const { TextArea } = Input;
function ButtonSave({
  body,
  handleOpenSave,
  haveDraft = true,
  label = LANG.SAVE,
}) {
  const history = useHistory();
  const isPC = useMediaQuery({
    query: KEY1.LAPTOP_WIDTH,
  });
  const dispatch = useDispatch();
  const { id } = useParams();
  const { dataStep2, openSaveButton, detailData } = useSelector(
    (state) => state.estimate
  );
  const [openModal, setOpenModal] = useState(false);
  const [checkNode, setCheckNode] = useState(false);
  const [note, setNote] = useState("");
  const [upversion, setUpverSion] = useState(false);
  const noteRef = useRef();
  const { CloneId, isRevup, status: statusQuery } = getQuerySearch();
  const isEditMode = id && !isRevup;
  const isRevupMode = id && isRevup;
  const isAddMode = !id;
  let isLastestVersionUpdated = false;
  let recordVersion = null;
  if (!_.isEmpty(detailData)) {
    const { version, versions } = detailData;

    if (
      (version && versions && versions.length === 1) ||
      Math.max(...versions) === version
    ) {
      isLastestVersionUpdated = true;
    } else {
      recordVersion = version;
    }
  }

  const isSingleButton = id && !isRevup && statusQuery == "1";
  // Check condition button

  // Button Draft Save
  const conditionButtonDraft = (id && statusQuery == "0") || (id && isRevup);

  const conditionButtonSaveReup = id && isRevup;
  const onOkWithNote = () => {
    setCheckNode(true);
    if (!note.trim()) {
      if (noteRef && noteRef.current) {
        noteRef.current.focus();
      }
      return;
    } else {
      handlePostEstimate("1");
    }
  };
  const onSave = (status = "0", upVersion = false) => {
    if (isEditMode && statusQuery == "0") {
      handlePostEstimate(status);
    } else if (isRevupMode && status == "0") {
      handlePostEstimate(status);
    } else if (!id) {
      handlePostEstimate(status);
    } else {
      setOpenModal(true);
      setUpverSion(upVersion);
    }
  };
  const handlePostEstimate = (status = "0") => {
    async function createEstimate() {
      try {
        dispatch(openLoading());
        body.status = status;
        delete body.rawWaterTypevalue;
        if (id) body.id = id;
        if (id && status === "1") body.note = note;
        body.standardLv = dataStep2.lv.current || null;

        body.options = body.options.filter((i) => i.checked);
        body.options.forEach((item) => {
          if (item.hasOwnProperty("isError")) {
            delete item.isError;
          }
          if (item.hasOwnProperty("checked")) {
            delete item.checked;
          }
        });

        // Update version cu
        if (!isLastestVersionUpdated && id) {
          body.recordVersion = recordVersion;
        }

        const saveNotUp = !isLastestVersionUpdated
          ? EstimateApi.updateOldEstimate
          : EstimateApi.updateEstimateNotUpVersion;

        !id
          ? await EstimateApi.createEstimate(body)
          : !isRevup
          ? await saveNotUp(body)
          : await EstimateApi.updateEstimate(body);
        showMessage(KEY1.INFO, COMMON_CONSTANT.BOM_C003, () =>
          history.push("/Estimate/list")
        );
      } catch (error) {
        showMessage(KEY1.ERROR, MSG_CATCH());
      } finally {
        dispatch(closeLoading());
        dispatch(closeSave());
      }
    }
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: createEstimate,
    });
  };
  const handleOpen = () => {
    if (handleOpenSave) {
      handleOpenSave();
    }
  };

  return (
    <>
      {!isSingleButton ? (
        <div onClick={() => handleOpen()} className={"actionLeft"}>
          <SaveOutlined
            className="mr4"
            style={{ fontWeight: "bold", fontSize: 16 }}
          />
          {label}
        </div>
      ) : (
        <div onClick={() => onSave("1")} className={"actionLeft"}>
          <SaveOutlined
            className="mr4"
            style={{ fontWeight: "bold", fontSize: 16 }}
          />
          {lang.SAVE_NORMAL}
        </div>
      )}

      <Drawer
        className="drawEstimate"
        height={300}
        zIndex={1000}
        title={<strong>{lang.SAVE}</strong>}
        placement="bottom"
        open={openSaveButton}
        onClose={() => dispatch(closeSave())}
      >
        <Row>
          {!isRevup && (
            <Col span={24}>
              <Button
                onClick={() => onSave("1")}
                className="buttonExport saveNormalEstimate"
              >
                {CloneId || !id ? label : lang.SAVE_NORMAL}
              </Button>
            </Col>
          )}
          {conditionButtonSaveReup && (
            <Col span={24}>
              <Button
                onClick={() => onSave("1", true)}
                className="buttonExport saveNormalEstimate"
              >
                {LANG.SAVE_UP_VERSION}
              </Button>
            </Col>
          )}
          {(!id || conditionButtonDraft) && (
            <Col span={24}>
              <Button
                onClick={() => onSave("0", isRevup)}
                className="buttonExport"
              >
                {isRevup ? lang.SAVE_DRAFT_REVUP : lang.SAVE_DRAFT}
              </Button>
            </Col>
          )}
        </Row>
      </Drawer>
      <Modal
        centered
        width={isPC ? "50%" : "90%"}
        bodyStyle={{ height: "auto", overflow: "auto" }}
        closeIcon={<></>}
        className=""
        open={openModal}
        footer={[
          <Button
            className="buttonPC button--info wAuto"
            onClick={() => onOkWithNote()}
          >
            {lang.OK}
          </Button>,
          <Button
            onClick={() => setOpenModal(false)}
            className="buttonPC --todo-- wAuto  button--outline"
          >
            {lang.CANCEL}
          </Button>,
        ]}
      >
        <Row className="mb10 flex-start" style={{ gap: 10 }}>
          <InfoCircleOutlined
            style={{ fontSize: isPC ? 40 : 30, color: "#faad14" }}
          />
          <span> {LANG.CHANGE_CONTENT}</span>

          {/* <Col span={20} className="ml7 mt5"></Col> */}
        </Row>

        <Row align={"middle"} gutter={[8, 8]}>
          <Col className={isPC ? "right" : ""} span={isPC ? 5 : 24}>
            <span className="bold mb5">
              {LANG.CHANGE_CONTENT}
              <span className="blod ml5 mark-required">*</span>
            </span>
          </Col>
          <Col span={isPC ? 19 : 24}>
            <TextArea
              ref={noteRef}
              className={`${
                checkNode && !note.trim() ? STYLE.BORDER_RED : ""
              } textNote`}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              rows={3}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default ButtonSave;
