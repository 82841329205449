import {
  ClearOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import FooterPage from "@src/component/footer/footerPage";
import { lang } from "@src/constants/common.const";
import { getFactories } from "@src/redux/auth";
import { Button, Col, Input, Modal, Row, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Loading from "../../component/Desktop/loading/loading";
import { COMMON_CONSTANT, KEY } from "../../constants/common.const";
import { LANG } from "../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../constants/utils";
import MachineApi from "./../../api/backend/machineManagement";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";

function ImportMachine() {
  const history = useHistory();
  const { isLeader } = useSelector((state) => state.auth);
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const columns = [
    {
      title: lang.NAME_SHEET,
      dataIndex: "sheetName",
      width: "30%",
      align: "center",
    },
    {
      title: lang.NUMBER_ROW,
      dataIndex: "rowNumber",
      width: "20%",
      align: "center",
    },
    {
      title: lang.MESSAGE,
      // dataIndex: "messages",
      width: "50%",
      align: "center",
      render: (_, row) => {
        return customMessageCol(row);
      },
    },
  ];
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(getFactories());
  }, [dispatch]);
  const uploadFile = () => {
    clearFile();
    inputFile.current.click();
  };
  const clearFile = () => {
    setFile(null);
    inputFile.current.value = null;
  };

  const onImportExcel = (file) => {
    const { files } = file.target;
    if (files[0]) {
      setFile(files[0]);
    }
  };

  const customMessageCol = (row) => {
    return (
      <>
        {row.messages.map((item) => {
          return <li>{item}</li>;
        })}
      </>
    );
  };

  // download file
  const downloadFileImport = async () => {
    try {
      setLoading(true);
      const res = !file
        ? await MachineApi.downloadTemplateImportMachine()
        : null;
      const blob = !file ? new Blob([res.data], {}) : file;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      const nameFile = !file
        ? "マシンテンプレートをインポートする.xlsx"
        : file.name;
      link.download = nameFile;
      link.click();
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };
  const importMachine = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const res = await MachineApi.importMachine(formData);
      if (res.data.result === 0) {
        showMessage(KEY.INFO, "取り込みに成功しました。", () => history.go(0));
        setData([]);
      } else {
        const messages = res.data.result === 1 ? "取込ファイルの形式が不正です。" : "取り込みに失敗しました。";
        showMessage(KEY.ERROR, messages);

        const arrData = [];
        (!isEmpty(res.data.data) ? res.data.data : []).forEach((item) => {
          for (const key in item.messages) {
            const temp = {
              sheetName: item.sheetName,
              rowNumber: key,
              messages: item.messages[key],
            };
            arrData.push(temp);
          }
        });
        setData(arrData);
        // setModalImport(false);
      }
    } catch (error) {
      let msg;
      if (error.response.data) {
        msg = error.response.data;
      }
      showMessage(KEY.ERROR, msg || MSG_CATCH());
    } finally {
      setLoading(false);
    }
  };

  const [modalImport, setModalImport] = useState(false);

  return (<>
    <Button
      className={"m-wAuto button--outline  --todo-- wAuto"}
      style={{ border: 'none', background: 'none', boxShadow: 'none', color: '#5f8de5' }}
      onClick={() => {
        setModalImport(true);
      }}
      icon={<CloudUploadOutlined style={{
        color: "#3b4873",
      }}
      />}
    >
      {/* <PlusOutlined style={{ color: "#3b4873" }} /> */}
      {LANG.REGISTER_INIT_DATA}
    </Button>
    <Modal
      width="90vw"
      open={modalImport}
      onCancel={() => setModalImport(false)}
      footer={<>
        {/* <Button
          className="buttonPC button--outline --todo-- wAuto"
          onClick={downloadFileImport}
          icon={
            <CloudDownloadOutlined
              style={{
                color: "#f50",
              }}
            />
          }
        >
          {lang.EXPORT}
        </Button> */}
        <Button
          disabled={!file}
          className="buttonPC button--info"
          onClick={() => importMachine()}
          icon={<CloudUploadOutlined />}
          style={{ width: 137 }}
        >
          {lang.REGISTRY}
        </Button>
      </>}
    >
      <Row gutter={[8, 8]} className="rowNotMargin">
        <Col className="mb10" span={24}>
          <Row gutter={[0, 0]} align="middle" justify="center">
            <Col style={{ marginRight: isPC ? 10 : 0 }} span={isPC ? 6 : 24}>
              <Button
                style={{
                  width: "100%",
                  height: 45,
                  maxHeight: 45,
                  minHeight: 45,

                  marginBottom: isPC ? 0 : 10,
                }}
                className="buttonPC button--info "
                onClick={uploadFile}
                icon={<UploadOutlined />}
              >
                {lang.SELECT_FILE}
              </Button>
            </Col>
            <Col span={isPC ? 12 : 24}>
              <Input
                type="text"
                disabled
                value={file ? file.name : ""}
                suffix={
                  <ClearOutlined onClick={clearFile} style={{ color: "red" }} />
                }
              />
              <input
                type="file"
                hidden
                accept=".xlsx"
                onChange={onImportExcel}
                ref={inputFile}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            className="gridSearch"
            columns={columns}
            dataSource={data}
            pagination={{ position: ["bottomCenter"], pageSize: 10 }}
          />
        </Col>
      </Row>
      <Loading show={loading} />
    </Modal>
  </>
  );
}

export default ImportMachine;
