export const LANG = {
    PROJECT_INFOMATION_INPUT:"条件インプット結果",
    ESTIMATE_NUMBER:"見積番号",
    CLIENT_NAME:"客先名称／工場名",
    CONSTRUCTION_NAME:"JOB名称",
    WATER_TYPE:"原水種類",
    CONDITION_INPUT:"設計条件",
    CONDITION_INPUT_STEP1:"条件インプット",
    PROJECT_INFOMATION_INPUT_STEP1:"プロジェクト情報入力",
    WATER_VOLUME:"原水取水量",
    WATER_VOLUME_STEP1:"原水量",
    PH:"原水PH",
    WATER_TURBIDITY:"原水濁度",
    WATER_SS:"原水SS濃度",
    WATER_FE:"原水鉄濃度",
    WATER_MN:"原水マンガン濃度",
    NET_VOLUMN_OF_WATER_RECOVERED:"正味回収水量",
    TREATED_WATER_TURBIDITY:"処理水濁度",
    OPERATING_TIME:"運転時間",
    REMARK:"備考",
    UNIT:"単位",
    ITEM:"項目",
    REFFENCE_VALUE:"REFFENCE VALUE",
    FIBER_LENGTH:"シリーズ",
    FIBER_LENGTH_STEP1:"シリーズ選択",
    COMPLETE_SPARE_MACHINE_QUANTITY:"本体予備基数",
    WITH_OR_WITHOUT_ACCESSORIES:"付属機器の選択",
    // May bom loc
    FILTRATION_PUMP:"ろ過ポンプ",
    //Bom  rua nguoc
    BACKWASH_PUMP:"逆洗ポンプ",
    AIR_WASHING_BLOWER:"空洗ブロワ",
    AIR_WASHING_BLOWER_SOUND:"空洗ブロワ防音BOX",
    HAVE:"有り",
    NOT_HAVE:"無し",
    REGULAR_USE_NUMBER:"常用台数",
    SPARE_MACHINE:"予備台数",
    OPTION:"本体オプション",
    LIMIT_SWITCH:"リミットスイッチ/",
    LONG_NECK:"ロングネック",
    AUTOMATIC_VALUE_MANUAL_HANDLE:"自動弁手動ハンドル",
    CHANGE_PIPING_MATERIAL:"配管材質変更",
    CHANGE_PIPE_CONECTION_POSITION:"配管取合位置変更",
    AMOUT_OF_MONEY:"金額",
    CANCEL:"キャンセル",
    OK:"OK",
    ACTION: "アクション",
    SAVE: "保存",
    SAVE_UP_VERSION:"保存",
    NEXT:"次",
    FIBER_TYPE:"繊維種",
    LV:"基準LV",
    NESSCES_AREA:"必要面積",
    SELECTED_RESULT:"本体選定結果",
    MODEL:"型式",
    QUANTITY:"常用運転数量",
    AUXILIARI_MACHINE:"補機選定結果",
    BACK: "戻る",
    PROJECT_INFOMATION :"プロジェクト情報",
    DESIGN_CONDITION:"設計条件",
    CREATED_DATE:"作成日",
    CREATE_ESTIMATE:"見積書作成",
    VIEW_ESTIMATE:"見積もりを見る",
    UPDATE_ESTIMATE:"見積もりを更新する",
    LIST_ESTIMATE:"見積一覧",
    VIEW_MACHINE:"機種情報",
    UPDATE_MACHINE:"機種更新",
    ADD_MACHINE:"機種新規作成",
    LIST_MACHINE:"機種一覧",
    HOME:"ホームページ",
    DEVICE_NAME:"設備名称",
    TREATED_WATER_VOLUMN:"処理水量",
    IN:"入口濁度",
    OUT:"処理水濁度",
    TABLE_COSST:"コスト情報",
    COMPLETED:"完了",
    DRAFT:"見積中",
    EXPLAIN:"必須項目（*）にご記入の上、「保存」ボタン又は「⊳選定」ボタンをクリックしてください。",
    FREE_INPUT:"自由記述",

    // import machine
    REGISTER_INIT_DATA:"機種表インポート",
    MODAL_TABLE:"機種表",
    EAGE_FIBER:"イーグルファイバー",
    COMMENT:"原則として塔数と同じ数量をご入力下さい",
    CREATED_TIME:"作成日",
    CREATER:"作成者",
    UPDATE_TIME:"更新日",
    UPDATER:"更新者",
    CHANGE_CONTENT:"更新内容",
    CONFIRM_FIBER_LENGTH:"最適な金額を比較するため、1000mm以下の場合に1000mmと1500mmのDC表を両方作成することをおすすめします。",
    TREATED_WATER_SS :"処理水SS濃度"

}
