import { RollbackOutlined, SendOutlined } from "@ant-design/icons";
import FooterPage from "@src/component/footer/footerPage";
import { IconNumber } from "@src/components/common/iconNumber.jsx";
import { Col, Collapse, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BreakLine from "../../../component/Desktop/breakLine";
import RowInput from "../../../component/Desktop/rowInput";
import VerticalInput from "../../../component/Desktop/verticalnput";
import { LANG } from "../../../constants/lang.const";
import { getQuerySearch } from "../../../constants/utils";
import { getPathEstimateWithStep } from "../../../utils/helpers";
import DescriptionFiberLength from "../descriptionFiberLength";
import "./../Estimate.scss";
import ButtonClone from "./buttonClone";
import ButtonMore from "./buttonMore";
import ButtonDelete from "./buttonDelete";
import ButtonEdit from "./buttonEdit";

const { Panel } = Collapse;

function ViewStep1({ basePath, dataDetail: data }) {
  const history = useHistory();
  const { version, isLastest } = getQuerySearch();
  const { id } = useParams();
  const viewUpdater = version && version != 1;
  const renderOptionMoney = data
    ? data.options.map((item, index) => {
        return (
          <>
            <RowInput
              labelCol={8}
              box
              name={
                <>
                  {item.optionName}
                  <br />
                  {"(千円)"}
                </>
              }
            >
              {item.value}
            </RowInput>
            {index !== data.options.length - 1} <BreakLine />
          </>
        );
      })
    : null;
  // Chemical injection device

  const listInjectionDevice = [
    {
      title: "PAC注入装置",
      value:
        data && data.havePacInjectionDevice === "1" ? LANG.HAVE : LANG.NOT_HAVE,
      key: "havePacInjectionDevice",
    },

    {
      title: "次亜注入装置",
      value:
        data && data.haveHypotoniaInjectionDevice === "1"
          ? LANG.HAVE
          : LANG.NOT_HAVE,
      key: "haveHypotoniaInjectionDevice",
    },

    {
      title: "PH調整剤注入装置",
      value:
        data && data.havePhAdjusterInjectionDevice === "1"
          ? LANG.HAVE
          : LANG.NOT_HAVE,
      key: "havePhAdjusterInjectionDevice",
    },
    {
      title: "制御盤",
      value: data && data.haveControlPanel === "1" ? LANG.HAVE : LANG.NOT_HAVE,
    },
  ];

  // Reder
  const renderListInjectionDevice = listInjectionDevice.map((item, index) => {
    return (
      <>
        {<BreakLine />}
        <RowInput labelCol={8} box name={item.title} key={index}>
          {item.value}
        </RowInput>
      </>
    );
  });
  // lis data estimationAuxMachine
  const estimationAuxMachine = [
    {
      have: data && data.estimationAuxMachine.haveAuxFiltrationPump === "1",
      title: LANG.FILTRATION_PUMP,
      regularUseNumber:
        data && data.estimationAuxMachine.auxFiltrationPumpRegularNumber,
      sparemachine:
        data && data.estimationAuxMachine.auxFiltrationPumpBackupMachine,
      model: data && data.estimationAuxMachine.auxFiltrationPumpModel,
    },
    {
      title: LANG.BACKWASH_PUMP,
      have: data && data.estimationAuxMachine.haveAuxBackwashPump === "1",
      regularUseNumber:
        data && data.estimationAuxMachine.auxBackwashPumpRegularNumber,
      sparemachine:
        data && data.estimationAuxMachine.auxBackwashPumpBackupMachine,
      model: data && data.auxBackwashPumpModel,
    },
    {
      title: LANG.AIR_WASHING_BLOWER,
      have: data && data.estimationAuxMachine.haveAuxAirWashingBlower === "1",
      regularUseNumber:
        data && data.estimationAuxMachine.auxAirWashingBlowerRegularNumber,
      sparemachine:
        data && data.estimationAuxMachine.auxAirWashingBlowerBackupMachine,
      model: data && data.estimationAuxMachine.auxAirWashingBlowerModels,
    },
    {
      title: <span>&nbsp;&nbsp;{LANG.AIR_WASHING_BLOWER_SOUND}</span>,
      have:
        data &&
        data.estimationAuxMachine.haveAuxAirWashingBlowerSndprfBox === "1",
      regularUseNumber:
        data &&
        data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber,
      sparemachine:
        data &&
        data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxBackupMachine,
      model: null,
    },
  ];

  const dataTableCondition = [
    {
      label: LANG.WATER_VOLUME_STEP1,
      unit: "m³/d",
      value: data && data.estimationConditionInput.inputRawWaterVolume,
    },
    {
      label: LANG.PH,
      value: data && data.estimationConditionInput.inputRawWaterPh,
    },
    {
      label: LANG.WATER_TURBIDITY,
      unit: "度",
      value: data && data.estimationConditionInput.inputRawWaterTurbidity,
      hidden: data && data.rawWaterType == "2",
    },
    {
      label: LANG.WATER_SS,
      unit: "mg/L",
      value: data && data.estimationConditionInput.inputRawWaterSsConc,
    },
    {
      label: LANG.WATER_FE,
      unit: "mg/L",
      value: data && data.estimationConditionInput.inputRawWaterIronConc,
      hidden: data && data.rawWaterType == "2",
    },
    {
      label: LANG.WATER_MN,
      unit: "mg/L",
      value: data && data.estimationConditionInput.inputRawWaterMgConc,
      hidden: data && data.rawWaterType == "2",
    },
    {
      label: LANG.TREATED_WATER_TURBIDITY,
      unit: "度以下",
      value: data && data.estimationConditionInput.inputTreatedWaterTurbidity,
      hidden: data && data.rawWaterType == "2",
    },
    {
      label: LANG.TREATED_WATER_SS,
      unit: "以下",
      value: data && data.estimationConditionInput.inputTreatedWaterSsConc,
      hidden: data && data.rawWaterType == "1",
    },
    {
      label: LANG.OPERATING_TIME,
      unit: "h/d",
      value: data && data.estimationConditionInput.inputOperatingTime,
    },
  ];

  // Render Condition Input
  const renderConditionInput = () => {
    return dataTableCondition
      .filter((i) => !i.hidden)
      .map((item, index) => (
        <React.Fragment key={index}>
          <RowInput
            labelCol={8}
            box
            name={
              <>
                {item.label}
                <br />
                {item.unit && `(${item.unit})`}
              </>
            }
          >
            {item.value}
          </RowInput>
          <BreakLine />
        </React.Fragment>
      ));
  };

  const renderAccessories = estimationAuxMachine.map((item, index) => {
    if (item.have) {
      return (
        <>
          <RowInput multipleCol name={item.title} box labelCol={8}>
            <Row>
              <Col span={12}>
                <VerticalInput label={LANG.REGULAR_USE_NUMBER}>
                  {item.regularUseNumber}
                </VerticalInput>
              </Col>
              <Col span={12}>
                <VerticalInput label={LANG.SPARE_MACHINE}>
                  {item.sparemachine}
                </VerticalInput>
              </Col>
            </Row>
          </RowInput>
          {index !== estimationAuxMachine.length - 1 && <BreakLine />}
        </>
      );
    }
    return (
      <>
        <RowInput name={item.title} box labelCol={8}>
          {LANG.NOT_HAVE}
        </RowInput>
        <BreakLine />
      </>
    );
  });

  return (
    <>
      <div className="des-content viewEstimate">
        <Collapse
          className="mt15"
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={1} />
                <span style={{ fontWeight: "bold" }}>
                  {LANG.PROJECT_INFOMATION_INPUT_STEP1}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.ESTIMATE_NUMBER}>
              {data && data.estimationNumber}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} box name={LANG.CLIENT_NAME}>
              {data && data.customerName}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} box name={LANG.CONSTRUCTION_NAME}>
              {data && data.constructionName}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} box name={LANG.CREATER}>
              {data && data.createUser}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} box name={LANG.CREATED_TIME}>
              {data && data.createDate}
            </RowInput>
            <BreakLine />
            {viewUpdater && data && data.updateUser && (
              <>
                <RowInput labelCol={8} box name={LANG.UPDATER}>
                  {data.updateUser}
                </RowInput>
                <BreakLine />
              </>
            )}
            {viewUpdater && data && data.updateDate && (
              <>
                <RowInput labelCol={8} box name={LANG.UPDATE_TIME}>
                  {data.updateDate}
                </RowInput>
                <BreakLine />
              </>
            )}
            {data && data.note && (
              <RowInput labelCol={8} box name={LANG.CHANGE_CONTENT}>
                {data.note}
              </RowInput>
            )}
          </Panel>
        </Collapse>
        <Collapse
          className="mt15"
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={2} />
                <span style={{ fontWeight: "bold" }}>{LANG.WATER_TYPE}</span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.WATER_TYPE}>
              {data &&
                data.rawWaterTypeValue + " - " + data.rawWaterDetailTypeValue}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          className="mt15"
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={3} />
                <span style={{ fontWeight: "bold" }}>
                  {LANG.CONDITION_INPUT_STEP1}
                </span>
              </div>
            }
            key={1}
          >
            {renderConditionInput()}
          </Panel>
        </Collapse>
        <Collapse
          className="mt15"
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={4} />
                <span style={{ fontWeight: "bold" }}>
                  {LANG.FIBER_LENGTH_STEP1}
                </span>
              </div>
            }
            key={1}
          >
            <RowInput labelCol={8} box name={LANG.FIBER_LENGTH_STEP1}>
              {data && data.fiberLength}㎜
              <DescriptionFiberLength filberLength={data && data.fiberLength} />
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          className="mt15"
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={5} />
                <span style={{ fontWeight: "bold" }}>
                  {LANG.COMPLETE_SPARE_MACHINE_QUANTITY}
                </span>
              </div>
            }
            key={1}
          >
            <RowInput
              labelCol={8}
              box
              name={LANG.COMPLETE_SPARE_MACHINE_QUANTITY}
            >
              {data && data.completeSpareMachineQuantity}
            </RowInput>
          </Panel>
        </Collapse>
        {data && data.options && data.options.length ? (
          <Collapse
            className="mt15"
            defaultActiveKey={["0"]}
            expandIconPosition="right"
          >
            <Panel
              header={
                <div className={"wrapTitle"}>
                  <IconNumber number={6} />
                  <span style={{ fontWeight: "bold" }}>{LANG.OPTION}</span>
                </div>
              }
              key={1}
            >
              {renderOptionMoney}
            </Panel>
          </Collapse>
        ) : (
          <></>
        )}
        <Collapse
          className="mt15"
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <IconNumber number={7} />
                <span style={{ fontWeight: "bold" }}>
                  {LANG.WITH_OR_WITHOUT_ACCESSORIES}
                </span>
              </div>
            }
            key={1}
          >
            {renderAccessories}
            {renderListInjectionDevice}
          </Panel>
        </Collapse>
      </div>
      <FooterPage>
        <Row>
          <Col span={8}>
            <div
              onClick={() => history.push("/Estimate/list")}
              className={"actionRight"}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          <Col span={8}>
            <ButtonMore isDraf={data && data.status === "0"}>
              <Col span={24}>
                <ButtonEdit status={data && data.status} />
              </Col>
              {isLastest && (
                <Col span={24}>
                  <ButtonEdit status={data && data.status} revup />
                </Col>
              )}
              <Col span={24}>
                <ButtonDelete />
              </Col>
            </ButtonMore>
          </Col>
          <Col span={8}>
            <div
              onClick={() => history.push(getPathEstimateWithStep(basePath, 2))}
              className={"actionRight"}
            >
              <SendOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              選定
            </div>
          </Col>
        </Row>
      </FooterPage>
    </>
  );
}

export default ViewStep1;
