import { ExportOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EstimateApi } from "../../../api/backend/estimate";
import { KEY, lang } from "../../../constants/common.const";
import { closeLoading, openLoading } from "../../../redux/estimate";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Table, Checkbox } from "antd";
import { UploadFileSpace } from "@src/page/Machine_Management/view.jsx";
import { isEmpty } from "lodash";
import { getIconByFileType } from "@src/page/Machine_Management/view.jsx";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import ApiMachine from "@src/api/backend/machineManagement";

const { Title } = Typography;
function ButtonExport() {
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });

  const { id } = useParams();
  const [showActionExport, setShowActionExport] = useState(false);
  const { version } = getQuerySearch();
  const dispatch = useDispatch();
  const { detailData: data } = useSelector((state) => state.estimate);
  const code = data ? data.estimationCode : "";
  const [openDC, setOpenDC] = useState(false);
  // Download
  const downloadFile = async (promise, nameFile, typeFile) => {
    try {
      dispatch(openLoading());
      const res = await promise;

      const blob = new Blob([res.data], {});
      let fileName = res.headers["content-disposition"].split("filename=")[1];
      fileName = fileName.substring(0, fileName.length - 1);
      fileName = decodeURI(fileName);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = fileName || nameFile + "_" + code + "." + typeFile;
      link.click();
    } catch (error) {
      let message = MSG_CATCH();
      if (error.response.status === 404) {
        message = "ファイルが見つかりません";
      }
      showMessage(KEY.ERROR, message);
    } finally {
      dispatch(closeLoading());
    }
  };

  const makeDownloadAction = async (type = "pdf") => {
    try {
      dispatch(openLoading());

      const data = await EstimateApi.download3(id, version, type);
      const blob = new Blob([data[0]], {});
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = decodeURI(data[1]);
      link.click();
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      dispatch(closeLoading());
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [listAttachMachine, setListAttachMachine] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await EstimateApi.getListExportFiels(id, version);
        setListAttachMachine(res.data);
      } catch (e) {
        showMessage(KEY.ERROR, MSG_CATCH());
      }
    };
    fetch();
  }, []);
  const {
    machineAttachs,
    airWashingBlowerAttachs,
    backwashPumpAttachs,
    filtrationPumpAttachs,
  } = listAttachMachine;
  const listIds = [];
  const convertData = (item, index) => {
    listIds.push(item.id);
    return {
      key: index,
      name: item.fileName,
      comment: item.comment,
      ...item,
    };
  };
  const listAttach = [
    {
      key: "title1",
      name: "イーグルファイバー機種表",
      isTitle: true,
      comment: "",
      children: (machineAttachs || []).map(convertData),
    },
    {
      key: "title2",
      name: "逆洗ポンプ機種表",
      isTitle: true,
      comment: "",
      children: (backwashPumpAttachs || []).map(convertData),
    },
    {
      key: "title3",
      name: "ろ過ポンプ機種表",
      isTitle: true,
      comment: "",
      children: (filtrationPumpAttachs || []).map(convertData),
    },
    {
      key: "title4",
      name: "空洗ブロワ機種表",
      isTitle: true,
      comment: "",
      children: (airWashingBlowerAttachs || []).map(convertData),
    },
    {
      key: "title5",
      name: "設計計算書",
      isTitle: true,
      comment: "",
    },
    {
      key: "title6",
      name: "バランス図",
      isTitle: true,
      comment: "",
    },
  ];

  const [selectedIds, setSelectedIds] = useState([]);
  const [isDesignCalculation, setIsDesignCalculation] = useState(false);
  const [isBalanceDiagram, setIsBalanceDiagram] = useState(false);

  const CheckboxAll = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          if (selectedIds.length === listIds.length) {
            setSelectedIds([]);
          } else {
            setSelectedIds(listIds);
          }
          setIsDesignCalculation(!isDesignCalculation);
          setIsBalanceDiagram(!isBalanceDiagram);
        }}
      />
      ファイル
    </>
  );

  const getcheckboxTitleType = (text) => {
    const thisType = listAttach.find((item) => item.name === text);
    const thisChildren = thisType.children;
    // isAllChildrenSelected check all children of this type is in selectedIds or not
    const isAllChildrenSelected = thisChildren.every((item) =>
      selectedIds.includes(item.id)
    );
    const onChange = () => {
      if (isAllChildrenSelected) {
        // If all children of this type is in selectedIds, remove all children of this type from selectedIds
        setSelectedIds(
          selectedIds.filter(
            (item) => !thisChildren.map((item) => item.id).includes(item)
          )
        );
      } else {
        // If all children of this type is not in selectedIds, add all children of this type to selectedIds
        setSelectedIds([
          ...selectedIds,
          ...thisChildren.map((item) => item.id),
        ]);
      }
    };
    return (
      <>
        <Checkbox
          className="mr5"
          onChange={onChange}
          checked={!isEmpty(thisChildren) && isAllChildrenSelected}
        />
        <b>{text}</b>
      </>
    );
  };

  const CheckboxDesignCalculation = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          setIsDesignCalculation(!isDesignCalculation);
        }}
        checked={isDesignCalculation}
      />
      <b>設計計算書</b>
      <ViewButton
        isEstimate={true}
        id={id}
        version={version}
        reportName={"step4"}
      />
    </>
  );

  const CheckboxBalanceDiagram = (
    <>
      <Checkbox
        className="mr5"
        onChange={() => {
          setIsBalanceDiagram(!isBalanceDiagram);
        }}
        checked={isBalanceDiagram}
      />
      <b>バランス図</b>
      <ViewButton
        isEstimate={true}
        id={id}
        version={version}
        reportName={"step5"}
      />
    </>
  );

  const attachFileColumns = [
    {
      //When check checkbox in header of table, all checkbox in table will be checked, include design calculation and balance diagram
      title: CheckboxAll,
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        if (text === "設計計算書") {
          return CheckboxDesignCalculation;
        }

        if (text === "バランス図") {
          return CheckboxBalanceDiagram;
        }

        if (record.isTitle) {
          return getcheckboxTitleType(text);
        }

        return (
          <>
            <Checkbox
              className="mr5"
              onChange={() => {
                if (selectedIds.includes(record.id)) {
                  setSelectedIds(
                    selectedIds.filter((item) => item !== record.id)
                  );
                } else {
                  setSelectedIds([...selectedIds, record.id]);
                }
              }}
              checked={selectedIds.includes(record.id)}
            />
            {text}
            <ViewButton id={record.id} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <div onClick={() => setShowActionExport(true)} className={"actionLeft"}>
        <ExportOutlined
          className="mr4"
          style={{ fontWeight: "bold", fontSize: 16 }}
        />
        {lang.EXPORT}
      </div>
      <Drawer
        className="drawEstimate"
        height={300}
        zIndex={1000}
        title={<strong>{"概算DC表"}</strong>}
        placement="bottom"
        open={openDC}
        onClose={() => setOpenDC(false)}
      >
        <Row>
          <Col span={24}>
            <ViewButton
              isEstimate={true}
              id={id}
              version={version}
              isButtonDefined={true}
              reportName={"step3"}
              button={
                <Button className="buttonExport saveNormalEstimate">
                  {"概算DC表プレビュー"}
                </Button>
              }
            />
          </Col>
          <Col span={24}>
            <Button
              onClick={() => makeDownloadAction("pdf")}
              className="buttonExport saveNormalEstimate"
            >
              {"PDF概算DC表"}
            </Button>
          </Col>
          {id && (
            <Col span={24}>
              <Button
                onClick={() => makeDownloadAction("xlsx")}
                className="buttonExport saveNormalEstimate"
              >
                {"EXCEL概算DC表"}
              </Button>
            </Col>
          )}
        </Row>
      </Drawer>
      <Drawer
        className="drawEstimate"
        height={200}
        zIndex={1000}
        open={showActionExport}
        title={<strong>{lang.EXPORT}</strong>}
        placement="bottom"
        onClose={() => setShowActionExport(false)}
      >
        <Row>
          <Col span={24}>
            <Button
              // onClick={() => makeDownloadAction()}
              onClick={() => setOpenDC(true)}
              className="buttonExport"
            >
              概算DC表
            </Button>
          </Col>
          <Col span={24}>
            <Button onClick={() => setModalOpen(true)} className="buttonExport">
              その他
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Modal
        open={modalOpen}
        width={"90vw"}
        className={isPC ? "" : "machine-content"}
        style={{ top: 20 }}
        onCancel={() => setModalOpen(false)}
        title={<div className="text-center pt15 pr5">その他</div>}
        classNames={{ headers: "text-center" }}
        footer={
          <>
            <Button
              className={"buttonPC button--info" + (isPC ? "" : " mb10 mr10")}
              onClick={() =>
                downloadFile(
                  EstimateApi.downloadAll(
                    id,
                    version,
                    isDesignCalculation,
                    isBalanceDiagram,
                    selectedIds
                  ),
                  lang.ATTACH_FILE,
                  "zip"
                )
              }
              icon={<CloudDownloadOutlined />}
              style={{ width: 137 }}
              disabled={
                isEmpty(selectedIds) &&
                !isBalanceDiagram &&
                !isDesignCalculation
              }
            >
              {lang.DOWNLOAD}
            </Button>
          </>
        }
      >
        <Table
          className="table-header-auto"
          columns={attachFileColumns}
          bordered={true}
          pagination={false}
          dataSource={listAttach}
          expandable={{
            defaultExpandAllRows: true,
          }}
        />
      </Modal>
    </>
  );
}

export default ButtonExport;

const ViewButton = ({
  id,
  isEstimate = false,
  reportName,
  version,
  isButtonDefined = false,
  button,
}) => {
  const dispatch = useDispatch();
  const viewFile = async () => {
    try {
      dispatch(openLoading());
      const api = isEstimate
        ? EstimateApi.viewFile(reportName, id, version)
        : ApiMachine.viewFile(id);
      const res = await api;
      const link = document.createElement("a");
      link.href = res.data;
      link.target = "_blank";
      link.click();
    } catch (err) {
      showMessage(
        KEY.ERROR,
        (err.response && err.response.data.msg) || MSG_CATCH()
      );
    } finally {
      dispatch(closeLoading());
    }
  };

  return (
    <span
      onClick={() => {
        viewFile();
      }}
    >
      {isButtonDefined ? (
        button
      ) : (
        <EyeOutlined
          className="ml8"
          style={{ fontSize: 14, color: "#1394df" }}
        />
      )}
    </span>
  );
};
