import {
  ApiOutlined,
  BookOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  DownOutlined,
  EditOutlined,
  EuroCircleOutlined,
  FullscreenOutlined,
  MenuOutlined,
  MoneyCollectOutlined,
  RightOutlined,
  RollbackOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import BoxContent from "@src/component/Desktop/content";
import FooterPage from "@src/component/footer/footerPage";
import { IconNumber } from "@src/components/common/iconNumber.jsx";

import { KEY as KEY1 } from "@src/constants/common.const";
import { Button, Col, Collapse, Descriptions, Input, Modal, Row } from "antd";
import { Filter, InfoCircle } from "iconsax-react";
import _, { isArray } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import TitlePage from "../../../component/Desktop/titlePage";
import { HeaderText } from "../../../components/common/headerText";
import { REGEX_INPUT, STYLE, lang } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";

import TextArea from "antd/es/input/TextArea";
import { useMediaQuery } from "react-responsive";
import { EstimateApi } from "../../../api/backend/estimate";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import {
  openSave,
  setDataStep1,
  setDataStep3,
  setFromToStep2,
} from "../../../redux/estimate";
import {
  caculatorSumMoneyandWeight,
  cloneObj,
  exactMultiplication,
  formatCurrencyJPY,
  getPathEstimateWithStep,
  isEmptyData,
  roundToDecimalPlaces,
} from "../../../utils/helpers";
import "./../../Estimate/Estimate.scss";
import "./../../Machine_Management/machine.scss";
import ButtonSave from "./buttonSave";
const { Panel } = Collapse;

function AddStep3({ dataStep1, basePath }) {
  const KEY = {
    MONNEY: "MONEY",
    WEIGHT: "WEIGHT",
    WEIGHT_UNIT: "WEIGHT_UNIT",
    PRICE_UNIT: "PRICE_UNIT",
    NUMBER_MACHINE: "NUMBER_MACHINE",
    PRICE_UNIT_PERCEN: "PRICE_UNIT_PERCEN",
    MANAGE_PERCEN: "MANAGE_PERCEN",
    BENEFIT_LOSS_PERCEN: "BENEFIT_LOSS_PERCEN",
    REMARK: "REMARK",
    OPTION_MONEY: "OPTION_MONEY",
  };
  const isPC = useMediaQuery({
    query: KEY1.LAPTOP_WIDTH,
  });
  const isAndroid = true;

  const history = useHistory();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [showModalEditPrice, setShowModalEditPrice] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const { dataStep2, dataStep3, formDataPostDefault } = useSelector(
    (state) => state.estimate
  );
  const inputRef = useRef();
  const [loading, setLoading] = useState(true);
  const [formErr, setFormErr] = useState({});
  const [data, setData] = useState({
    estimationConst: {
      otherFees: 0,
      instrumentationWorkFee: 250,
      transportationFee: 160,
      trialRunAdjustmentFee: 100,
      attendanceFee: 150,
      designDocumentPreparationFee: 150,
      pacInjectionDeviceNumber: 1,
      pacInjectionDevicePrice: 0,
      hypotoniaInjectionDeviceNumber: 1,
      hypotoniaInjectionDevicePrice: 0,
      phAdjusterInjectionDeviceNumber: 1,
      phAdjusterInjectionDevicePrice: 0,
      controlPanelNumber: 1,
      controlPanelPrice: 0,
      commissioningSvFees: 150,
      reserveFund: 10,
      designNumber: 1,
      designFee: 1000,
      bookCreationNumber: 1,
      bookCreationFee: 500,
    },
  });

  let day = currentDate.getDate();
  if (day < 10) day = "0" + day;
  let month = currentDate.getMonth() + 1;
  if (month < 10) month = "0" + month;
  const year = currentDate.getFullYear();
  const path = !id ? "/Estimate/Add" : "/Estimate/Edit/" + id;
  // Định dạng ngày tháng năm
  const formattedDate = year + "/" + month + "/" + day;
  const infoProject = [
    {
      title: LANG.ESTIMATE_NUMBER,
      content: dataStep1.estimationNumber,
    },
    {
      title: LANG.CLIENT_NAME,
      content: dataStep1.customerName,
    },
    {
      title: LANG.CONSTRUCTION_NAME,
      content: dataStep1.constructionName,
    },

    {
      title: LANG.CREATED_DATE,
      content: formattedDate,
      inlineDisplay: true,
    },
  ];

  const conditionDesign = [
    {
      title: LANG.WATER_TYPE,
      content: dataStep1.rawWaterTypevalue || "",
    },
    {
      title: (
        <>
          {LANG.TREATED_WATER_VOLUMN}
          <br />
          (㎥/h)
        </>
      ),

      content: dataStep1.estimationConditionInput
        ? Math.round(
            (dataStep1.estimationConditionInput.inputRawWaterVolume /
              dataStep1.estimationConditionInput.inputOperatingTime) *
              10
          ) / 10
        : "",
    },
    {
      title: dataStep1.rawWaterType === "1" ? LANG.IN : LANG.WATER_SS,
      content:
        dataStep1.rawWaterType === "1"
          ? dataStep1.estimationConditionInput
            ? dataStep1.estimationConditionInput.inputRawWaterTurbidity
            : ""
          : dataStep1.estimationConditionInput
          ? dataStep1.estimationConditionInput.inputRawWaterSsConc
          : "",
    },
    {
      title: dataStep1.rawWaterType === "1" ? LANG.OUT : LANG.TREATED_WATER_SS,
      content:
        dataStep1.rawWaterType === "1"
          ? dataStep1.estimationConditionInput
            ? dataStep1.estimationConditionInput.inputTreatedWaterTurbidity
            : ""
          : dataStep1.estimationConditionInput
          ? dataStep1.estimationConditionInput.inputTreatedWaterSsConc
          : "",
    },
  ];

  // Show Modal Info
  const openModalInfoTableCost = (item) => {
    setVisible(true);
    setDataModal({
      ...item,
      content: item.content.map((i) => {
        i.isEdit = false;
        return i;
      }),
    });
  };

  // Show Data Modal Edit
  const openModalEditTableCost = (item) => {
    setShowModalEditPrice(true);
    setDataModal(item);
  };
  const closeModalInfoTableCost = () => {
    setVisible(false);
    setDataModal(null);
  };

  //close modal edit
  const closeModalEditTableCost = () => {
    setShowModalEditPrice(false);
    setDataModal(null);
    setFormErr({});
  };

  const getErr = (keyInput) => {
    if (!keyInput) return false;
    let key;
    if (Array.isArray(keyInput)) {
      key = keyInput[keyInput.length - 1];
    } else {
      key = keyInput;
    }
    if (!_.isEmpty(formErr)) {
      const keys = Object.keys(formErr);
      if (keys.includes(key)) {
        return true;
      }
    }
    return false;
  };
  // get ref
  const getRef = (keyInput) => {
    if (!keyInput) return null;
    let key;
    if (Array.isArray(keyInput)) {
      key = keyInput[keyInput.length - 1];
    } else {
      key = keyInput;
    }
    if (!_.isEmpty(formErr)) {
      const keys = Object.keys(formErr);
      if (keys[0] === key) {
        return inputRef;
      }
    }
    return null;
  };

  // focus err
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [formErr]);

  // check validate
  const checkValidateInput = () => {
    const objErr = {};
    if (!dataModal) return false;
    let contentArr = dataModal.content || [];
    contentArr = contentArr.filter((i) => i.isEdit && i.key !== KEY.REMARK);
    contentArr.forEach((item) => {
      if (item.content === "" || item.content === null) {
        const keyArr = item.keyInput;
        if (Array.isArray(keyArr)) {
          objErr[keyArr[keyArr.length - 1]] = true;
        } else {
          objErr[keyArr] = true;
        }
      }
    });
    setFormErr(objErr);

    return _.isEmpty(objErr);
  };
  // Ok modal edit
  const handleChangeValueCost = () => {
    if (!checkValidateInput()) return;
    const data =
      dataModal && dataModal.content ? cloneObj(dataModal).content : [];
    const valueUpdate = {};
    data.forEach((item) => {
      if (item.isEdit) {
        const value = item.content;
        const key = item.keyInput;
        if (Array.isArray(key)) {
          valueUpdate[key[0]] = valueUpdate[key[0]]
            ? { ...valueUpdate[key[0]], [[key[1]]]: value }
            : { [[key[1]]]: value };
        }
      }
    });
    handleChangeDataInputByValue(valueUpdate);

    setDataModal(null);
    setShowModalEditPrice(false);
    setFormErr({});
  };
  const renderItemTableCost = (tableCost, { isNotMoney }) => {
    return tableCost.map((info, index) => {
      const showField = info.content.find((i) => i.isShow);
      const text = showField ? (showField.content ? showField.content : 0) : 0;
      const isEdit = info.content.find((i) => i.isEdit);
      const unit = showField.unit;
      const title = unit ? (
        <>
          {info.title}
          <br />
          {`(${unit})`}
        </>
      ) : (
        info.title
      );
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={
            <div
              style={{ cursor: "pointer" }}
              onClick={() => openModalInfoTableCost(info)}
            >
              {title}
            </div>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: "8px",
            whiteSpace: "pre-wrap",
          }}
        >
          <div
            className="flex-center"
            style={{ justifyContent: "space-between" }}
          >
            {isEdit ? (
              <EditOutlined
                onClick={() => openModalEditTableCost(info)}
                style={{
                  width: 30,
                  height: 30,
                  color: "#0080cb",
                  cursor: "pointer",
                }}
              />
            ) : (
              <span></span>
            )}
            <span>{`${formatCurrencyJPY(text || 0)} ${
              isNotMoney ? "" : "(千円)"
            }`}</span>
          </div>
        </Descriptions.Item>
      );
    });
  };
  // change Data
  const handleChangeDataInputByValue = (newValue) => {
    let newData = cloneObj(data);
    for (let key in newValue) {
      newData = { ...newData, [key]: { ...newData[key], ...newValue[key] } };
    }
    setData(newData);
  };
  // handle change inpiut modal
  const handleChangeInputData = (key, newValue, rule, keyInput) => {
    console.log(rule);

    if (rule && !newValue.match(rule) && newValue) {
      return;
    }
    if (rule === REGEX_INPUT.interger) {
      newValue = String(newValue);
      if (newValue.length > 9) {
        return;
      }
      if (newValue.length > 1 && newValue[0] == "0") {
        newValue = newValue.slice(1);
      }
    }

    const arrContent = dataModal ? dataModal.content : [];
    const indexMoney = arrContent.findIndex((i) => i.key === KEY.MONNEY);
    const indexRemark = arrContent.findIndex((i) => i.key === KEY.REMARK);
    const indexOptionMoney = arrContent.findIndex(
      (i) => i.key === KEY.OPTION_MONEY
    );

    const indexNumberMachine = arrContent.findIndex(
      (i) => i.key === KEY.NUMBER_MACHINE
    );
    const indexPriceUnitPerCen = arrContent.findIndex(
      (i) => i.key === KEY.PRICE_UNIT_PERCEN
    );
    const indexManagePercen = arrContent.findIndex(
      (i) => i.key === KEY.MANAGE_PERCEN
    );
    const indexPriceUnit = arrContent.findIndex(
      (i) => i.key === KEY.PRICE_UNIT
    );
    const indexBenefitLossPercen = arrContent.findIndex(
      (i) => i.key === KEY.BENEFIT_LOSS_PERCEN
    );
    if (
      key === KEY.PRICE_UNIT &&
      indexMoney >= 0 &&
      indexPriceUnit >= 0 &&
      indexNumberMachine >= 0
    ) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexPriceUnit].content = newValue;
      newDataModal.content[indexMoney].content = exactMultiplication(
        newDataModal.content[indexNumberMachine].content,
        newValue
      );

      setDataModal(newDataModal);
    }
    if (
      key === KEY.NUMBER_MACHINE &&
      indexNumberMachine >= 0 &&
      indexPriceUnit >= 0
    ) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexNumberMachine].content = newValue;
      newDataModal.content[indexMoney].content = exactMultiplication(
        newDataModal.content[indexPriceUnit].content,
        newValue
      );

      setDataModal(newDataModal);
    }

    // REFUND PERCENT
    if (
      (key === KEY.PRICE_UNIT_PERCEN && indexMoney >= 0,
      indexPriceUnitPerCen >= 0)
    ) {
      if (Number(newValue) < 0 || Number(newValue) > 100) {
        return;
      }
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexPriceUnitPerCen].content = newValue;
      newDataModal.content[indexMoney].content = (newValue * totalCost) / 100;

      setDataModal(newDataModal);
    }
    // MANAGE PERCEN
    if (
      (key === KEY.MANAGE_PERCEN && indexMoney >= 0, indexManagePercen >= 0)
    ) {
      if (Number(newValue) < 0 || Number(newValue) > 100) {
        return;
      }
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexManagePercen].content = newValue;
      newDataModal.content[indexMoney].content = (newValue * dcCost) / 100;

      setDataModal(newDataModal);
    }

    // BENEFIT_LOSS_PERCENT
    if (
      (key === KEY.BENEFIT_LOSS_PERCEN && indexMoney >= 0,
      indexBenefitLossPercen >= 0)
    ) {
      if (Number(newValue) < 0 || Number(newValue) > 99) {
        return;
      }
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexBenefitLossPercen].content = newValue;
      const sellingPrice = roundToDecimalPlaces(
        (costOfSale.money * 100) / (100 - newValue),
        -3
      );
      newDataModal.content[indexMoney].content =
        sellingPrice - costOfSale.money;

      setDataModal(newDataModal);
    }
    // key money
    if (key === KEY.MONNEY && indexMoney >= 0) {
      const newDataModal = cloneObj(dataModal);
      console.log(newValue);
      newDataModal.content[indexMoney].content =
        rule && !isEmptyData(newValue) ? Number(newValue) : newValue;
      console.log(newDataModal);
      setDataModal(newDataModal);
    }

    // key remark
    if (key === KEY.REMARK && indexRemark >= 0) {
      const newDataModal = cloneObj(dataModal);
      newDataModal.content[indexRemark].content = newValue;
      setDataModal(newDataModal);
    }
    // KEY OPTION MONEY
    if (key === KEY.OPTION_MONEY && indexOptionMoney >= 0 && indexMoney) {
      const keyIn = keyInput[1];
      const exactlyIndex = arrContent.findIndex(
        (i) => (i.key === KEY.OPTION_MONEY) & (i.keyInput[1] === keyIn)
      );
      const newDataModal = cloneObj(dataModal);
      const oldMoneyItem = newDataModal.content[exactlyIndex].content;
      newDataModal.content[exactlyIndex].content = Number(newValue);
      const oldTotalMonety = newDataModal.content[indexMoney].content;
      newDataModal.content[indexMoney].content =
        Number(oldTotalMonety) +
        exactMultiplication(
          Number(newValue) - Number(oldMoneyItem),
          data.numberOfMachines
        );
      setDataModal(newDataModal);
    }
  };

  // key down input
  const hanldeKeyDown = (rule) => (e) => {
    if (
      rule &&
      !e.key.match(rule) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "ArrowRight" &&
      e.key !== "ArrowLeft" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };
  // Get Data Step3
  useEffect(() => {
    setLoading(true);
    EstimateApi.getDataStep3({
      ...dataStep1,
      standardLv: dataStep2.lv.current,
    })
      .then((res) => {
        setLoading(false);
        if (dataStep1.options) {
          let objOption = {};
          dataStep1.options
            .filter((i) => i.checked)
            .forEach((i) => {
              objOption[i.optionId] = i.value;
            });

          const currData = {
            ...res.data,
            estimationConst: {
              ...res.data.estimationConst,
              ...dataStep3.estimationConst,
            },
            options: objOption,
          };
          if (!_.isEmpty(dataStep1)) {
            const newData = cloneObj(currData);
            const newEstimationConst = cloneObj(newData.estimationConst);
            if (dataStep1.havePacInjectionDevice === "0") {
              newEstimationConst.pacInjectionDeviceNumber = 0;
            }
            if (dataStep1.haveHypotoniaInjectionDevice === "0")
              newEstimationConst.hypotoniaInjectionDeviceNumber = 0;
            if (dataStep1.havePhAdjusterInjectionDevice === "0")
              newEstimationConst.phAdjusterInjectionDeviceNumber = 0;
            if (dataStep1.haveControlPanel === "0")
              newEstimationConst.controlPanelNumber = 0;
            newData.estimationConst = newEstimationConst;
            setData(newData);
          }
        }
      })
      .catch(() => {
        showMessage(KEY.ERROR, MSG_CATCH());
      });
  }, [dataStep1, dataStep2.lv, dataStep3]);
  const infoProjectArr = [
    {
      title: LANG.PROJECT_INFOMATION,
      icon: <InfoCircle className="first-icon" />,
      content: infoProject,
      defaultExpand: true,
    },
  ];
  const conditionArr = [
    {
      title: LANG.DESIGN_CONDITION,
      icon: <Filter className="first-icon" />,
      content: conditionDesign,
    },
  ];

  const renderInfo = (listInfo, active = true) =>
    listInfo.map((item, index) => {
      return (
        <Col span={24} className="mt8" key={index}>
          <BoxContent style={{ margin: 0, padding: 0 }}>
            <Collapse
              defaultActiveKey={"1"}
              expandIconPosition="right"
              style={{ padding: 0 }}
            >
              <Panel
                header={
                  <>
                    <div
                      className="flex-center"
                      style={{ justifyContent: "flex-start" }}
                    >
                      {item.icon}
                      {item.title}
                    </div>
                  </>
                }
                key="1"
              >
                <Descriptions size="small" column={1} bordered>
                  {item.content.map((info, index) => {
                    const { rule, ruleKeyDown } = info;
                    const inputRule =
                      REGEX_INPUT[rule] != "undefined"
                        ? REGEX_INPUT[rule]
                        : REGEX_INPUT.interger;
                    const inputRuleKey =
                      REGEX_INPUT[ruleKeyDown] != "undefined"
                        ? REGEX_INPUT[ruleKeyDown]
                        : REGEX_INPUT.interger;

                    const type =
                      isAndroid && rule !== "all" ? "number" : "text";
                    return (
                      <Descriptions.Item
                        span={1}
                        key={index}
                        label={
                          <span onClick={() => active && setVisible(true)}>
                            {info.title}
                          </span>
                        }
                        labelStyle={{
                          width: "35%",
                          fontWeight: "bold",
                          padding: "8px 8px",
                        }}
                        contentStyle={{
                          padding: !isArray(info.content) ? "8px" : "0px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {!isArray(info.content) && !info.isEdit && info.content}
                        {!isArray(info.content) && info.isEdit && (
                          <Input
                            className={
                              getErr(info.keyInput) ? STYLE.BORDER_RED : ""
                            }
                            ref={getRef(info.keyInput)}
                            type={type}
                            onKeyDown={hanldeKeyDown(inputRuleKey)}
                            min={0}
                            value={info.content}
                            onChange={(e) =>
                              handleChangeInputData(
                                info.key,
                                e.target.value,
                                inputRule,
                                info.keyInput
                              )
                            }
                          />
                        )}
                        {isArray(info.content) && (
                          <Descriptions
                            key={index}
                            size="small"
                            column={1}
                            bordered
                          >
                            {info.content.map((item, index) => {
                              const { rule, ruleKeyDown, key } = item;
                              const inputRule =
                                REGEX_INPUT[rule] != "undefined"
                                  ? REGEX_INPUT[rule]
                                  : REGEX_INPUT.interger;
                              const inputRuleKey =
                                REGEX_INPUT[ruleKeyDown] != "undefined"
                                  ? REGEX_INPUT[ruleKeyDown]
                                  : REGEX_INPUT.interger;
                              const type =
                                isAndroid && rule !== "all" ? "number" : "text";

                              return (
                                <Descriptions.Item
                                  span={1}
                                  label={item.title}
                                  labelStyle={{
                                    width: "45%",
                                    fontWeight: "semi-bold",
                                  }}
                                >
                                  {!item.isEdit && item.content}
                                  {item.isEdit && (
                                    <Input
                                      className={
                                        getErr(item.keyInput)
                                          ? STYLE.BORDER_RED
                                          : ""
                                      }
                                      ref={getRef(item.keyInput)}
                                      type={type}
                                      onKeyDown={hanldeKeyDown(inputRuleKey)}
                                      min={0}
                                      value={info.content}
                                      onChange={(e) =>
                                        handleChangeInputData(
                                          info.key,
                                          e.target.value,
                                          inputRule,
                                          info.keyInput
                                        )
                                      }
                                    />
                                  )}
                                </Descriptions.Item>
                              );
                            })}
                          </Descriptions>
                        )}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Panel>
            </Collapse>
          </BoxContent>
        </Col>
      );
    });
  const renderDesContent = (arr, { isOpenModal = true }) => {
    return arr.content.map((info, index) => {
      const { rule, ruleKeyDown, unit, key } = info;
      const title = unit ? (
        <>
          {info.title}
          <br />
          {`(${unit})`}
        </>
      ) : (
        info.title
      );

      const inputRule =
        REGEX_INPUT[rule] !== undefined
          ? REGEX_INPUT[rule]
          : REGEX_INPUT.interger;
      const inputRuleKey =
        REGEX_INPUT[ruleKeyDown] !== undefined
          ? REGEX_INPUT[ruleKeyDown]
          : REGEX_INPUT.interger;
      const type = isAndroid && rule !== "all" ? "number" : "text";
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={
            <span onClick={() => (isOpenModal ? setVisible(true) : null)}>
              {title}
            </span>
          }
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: !isArray(info.content) ? "8px" : "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!isArray(info.content) && !info.isEdit
            ? !isNaN(info.content) &&
              info.content !== null &&
              info.content !== ""
              ? formatCurrencyJPY(info.content)
              : info.content
            : null}
          {!isArray(info.content) &&
            info.isEdit &&
            (key !== KEY.REMARK ? (
              <Input
                className={getErr(info.keyInput) ? STYLE.BORDER_RED : ""}
                ref={getRef(info.keyInput)}
                type={type}
                onKeyDown={hanldeKeyDown(inputRuleKey)}
                min={0}
                value={info.content}
                onChange={(e) =>
                  handleChangeInputData(
                    info.key,
                    e.target.value,
                    inputRule,
                    info.keyInput
                  )
                }
              />
            ) : (
              <TextArea
                // className={getErr(info.keyInput) ? STYLE.BORDER_RED : ""}
                // ref={getRef(info.keyInput)}
                type={type}
                style={{
                  background: "white",
                }}
                onKeyDown={hanldeKeyDown(inputRuleKey)}
                min={0}
                value={info.content}
                onChange={(e) =>
                  handleChangeInputData(
                    info.key,
                    e.target.value,
                    inputRule,
                    info.keyInput
                  )
                }
                className="textNote"
                rows={2}
              />
            ))}
          {isArray(info.content) && (
            <Descriptions key={index} size="small" column={1} bordered>
              {info.content.map((item, index) => {
                const { rule, ruleKeyDown } = item;
                const inputRule =
                  REGEX_INPUT[rule] != "undefined"
                    ? REGEX_INPUT[rule]
                    : REGEX_INPUT.interger;
                const inputRuleKey =
                  REGEX_INPUT[ruleKeyDown] != "undefined"
                    ? REGEX_INPUT[ruleKeyDown]
                    : REGEX_INPUT.interger;
                const type = isAndroid && rule !== "all" ? "number" : "text";

                return (
                  <Descriptions.Item
                    span={1}
                    label={item.title}
                    labelStyle={{
                      width: "45%",
                      fontWeight: "semi-bold",
                    }}
                  >
                    {!item.isEdit && item.content}
                    {item.isEdit && (
                      <Input
                        className={
                          getErr(item.keyInput) ? STYLE.BORDER_RED : ""
                        }
                        ref={getRef(item.keyInput)}
                        type={type}
                        onKeyDown={hanldeKeyDown(inputRuleKey)}
                        min={0}
                        value={info.content}
                        onChange={(e) =>
                          handleChangeInputData(
                            info.key,
                            e.target.value,
                            inputRule,
                            info.keyInput
                          )
                        }
                      />
                    )}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
        </Descriptions.Item>
      );
    });
  };

  //push data step3
  const pushDataStep3 = () => {
    dispatch(
      setDataStep3({
        estimationConst: data.estimationConst,
      })
    );
    dispatch(
      setDataStep1({
        ...cloneObj(dataStep1),
        options: optionPost,
      })
    );
    dispatch(setFromToStep2("step3"));
  };

  const tableCost1 = [
    {
      title: "イーグルファイバー",
      content: [
        {
          title: "型式",
          content: data.machineSelected || "",
        },
        {
          title: "仕様",
          content: data.eagleFiberTowerDimension || "",
        },
        {
          title: "数量(基)",
          content: data.numberOfMachines || "",
        },
        {
          title: "単位質量(kg)",
          content: data.eagleFiberProductWeight || 0,
        },
        {
          title: "単価(千円)",
          content: Math.round(data.eagleFiberUnitPrice) || "",
        },
        {
          title: "質量(kg)",
          content: data
            ? Math.round(
                Number(data.eagleFiberProductWeight) *
                  Number(data.numberOfMachines)
              )
            : "",
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content: data
            ? exactMultiplication(
                data.eagleFiberUnitPrice,
                data.numberOfMachines
              )
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },

    {
      title: "計装作業",
      content: [
        {
          title: "数量(式)",
          content: data.numberOfMachines || "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst &&
            Math.round(data.estimationConst.instrumentationWorkFee),
          isEdit: true,
          keyInput: ["estimationConst", "instrumentationWorkFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.instrumentationWorkFee *
                data.numberOfMachines
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.instrumentationWorkFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "instrumentationWorkFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "運搬費",
      content: [
        {
          title: "数量(式)",
          content: data.numberOfMachines || "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst &&
            Math.round(data.estimationConst.transportationFee),
          isEdit: true,
          keyInput: ["estimationConst", "transportationFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            data.estimationConst.transportationFee * data.numberOfMachines,
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.transportationFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "transportationFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "試運転調整費・単価",
      content: [
        {
          title: "数量(日)",
          content: data ? 2 + (data.numberOfMachines - 1) * 1 : "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          isEdit: true,
          title: "単価(千円)",
          content:
            data.estimationConst &&
            Math.round(data.estimationConst.trialRunAdjustmentFee),
          keyInput: ["estimationConst", "trialRunAdjustmentFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? (2 + (data.numberOfMachines - 1) * 1) *
              data.estimationConst.trialRunAdjustmentFee
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.trialRunAdjustmentFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "trialRunAdjustmentFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "立会対応費",
      content: [
        {
          title: "数量(式)",
          content: data.numberOfMachines || 0,
          key: KEY.NUMBER_MACHINE,
        },

        {
          isEdit: true,
          title: "単価(千円)",
          content: data.estimationConst
            ? Math.round(data.estimationConst.attendanceFee)
            : 0,
          keyInput: ["estimationConst", "attendanceFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? data.estimationConst.attendanceFee * data.numberOfMachines
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.attendanceFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "attendanceFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "設計図書作成費",
      content: [
        {
          title: "数量(式)",
          content: data.numberOfMachines || 0,
          key: KEY.NUMBER_MACHINE,
        },

        {
          isEdit: true,
          title: "単価(千円)",
          content: data.estimationConst
            ? Math.round(data.estimationConst.designDocumentPreparationFee)
            : 0,
          keyInput: ["estimationConst", "designDocumentPreparationFee"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content: data.estimationConst
            ? data.estimationConst.designDocumentPreparationFee *
              data.numberOfMachines
            : "",
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.designDocumentPreparationFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "designDocumentPreparationFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  let optionPost = cloneObj(dataStep1.options);
  //  merge option
  if (data.options) {
    const itemOptionOftableCost1Array = {
      title: LANG.OPTION,
      content: [],
    };
    const optionArray = Object.keys(data.options);
    optionArray.forEach((key) => {
      let optionName = "";
      const mapOptionIndex = optionPost.findIndex((i) => i.optionId == key);
      if (mapOptionIndex >= 0) {
        optionName = optionPost[mapOptionIndex].optionName;
        optionPost[mapOptionIndex].value = data.options[key]
          ? Number(data.options[key])
          : 0;
      }
      const value = data.options[key] ? Number(data.options[key]) : 0;
      itemOptionOftableCost1Array.content.push({
        title: optionName,
        content: value,
        isEdit: true,
        keyInput: ["options", key],
        key: KEY.OPTION_MONEY,
        unit: "千円",
      });
    });
    const moneyOption = itemOptionOftableCost1Array.content.reduce(
      (money, item) => {
        return money + item.content;
      },
      0
    );

    itemOptionOftableCost1Array.content.push({
      title: "金額(千円)",
      content: exactMultiplication(moneyOption, data.numberOfMachines),
      isShow: true,
      key: KEY.MONNEY,
    });
    itemOptionOftableCost1Array.content.push({
      title: lang.REMARK,
      content: data.estimationConst && data.estimationConst.bodyOptionsComment,
      key: KEY.REMARK,
      isEdit: dataStep1.options && dataStep1.options.length > 0,
      keyInput: ["estimationConst", "bodyOptionsComment"],
      rule: "all",
      ruleKeyDown: "all",
    });
    tableCost1.push(itemOptionOftableCost1Array);
  }
  const tableCost2 = [
    {
      title: "ろ過ポンプ",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpModel,
        },
        {
          title: "仕様",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpRegularNumber,
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxFiltrationPumpUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxFiltrationPumpWeight,
                data.estimationAuxMachine.auxFiltrationPumpRegularNumber
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxFiltrationPumpUnitPrice,
              data.estimationAuxMachine.auxFiltrationPumpRegularNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];
  const tableCost3 = [
    {
      title: "逆洗ポンプ",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpModel,
        },
        {
          title: "仕様",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpRegularNumber,
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxBackwashPumpUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxBackwashPumpWeight,
                data.estimationAuxMachine.auxBackwashPumpRegularNumber
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxBackwashPumpUnitPrice,
              data.estimationAuxMachine.auxBackwashPumpRegularNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];
  const tableCost4 = [
    {
      title: "空洗ブロワ",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerModel,
        },
        {
          title: "仕様",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerCapacity,
        },
        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerRegularNumber,
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxAirWashingBlowerWeight,
                data.estimationAuxMachine.auxAirWashingBlowerRegularNumber
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxAirWashingBlowerUnitPrice,
              data.estimationAuxMachine.auxAirWashingBlowerRegularNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];
  const tableCost5 = [
    {
      title: "防音ボックス",
      content: [
        {
          title: "型式",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxModel,
        },

        {
          title: "数量(台)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxRegularNumber,
        },
        {
          title: "単位質量(kg)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
        },
        {
          title: "単価(千円)",
          content:
            data.estimationAuxMachine &&
            data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
        },
        {
          title: "質量(kg)",
          content:
            data.estimationAuxMachine &&
            Math.round(
              exactMultiplication(
                data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxWeight,
                data.estimationAuxMachine
                  .auxAirWashingBlowerSndprfBoxRegularNumber
              )
            ),
          key: KEY.WEIGHT,
        },
        {
          title: "金額(千円)",
          content:
            data.estimationAuxMachine &&
            exactMultiplication(
              data.estimationAuxMachine.auxAirWashingBlowerSndprfBoxUnitPrice,
              data.estimationAuxMachine
                .auxAirWashingBlowerSndprfBoxRegularNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];
  const tableCost6 = [
    {
      title: "PAC注入装置",
      content: [
        {
          title: "数量(式)",
          content: data.estimationConst
            ? data.estimationConst.pacInjectionDeviceNumber
            : 0,
          isEdit:
            dataStep1 && dataStep1.havePacInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "pacInjectionDeviceNumber"],
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content: data.estimationConst
            ? data.estimationConst.pacInjectionDevicePrice
            : 0,
          isEdit:
            dataStep1 && dataStep1.havePacInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "pacInjectionDevicePrice"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.pacInjectionDevicePrice,
              data.estimationConst.pacInjectionDeviceNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.pacInjectionDeviceComment,
          key: KEY.REMARK,
          isEdit:
            dataStep1 && dataStep1.havePacInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "pacInjectionDeviceComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "次亜注入装置",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst &&
            data.estimationConst.hypotoniaInjectionDeviceNumber,
          isEdit:
            dataStep1 && dataStep1.haveHypotoniaInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "hypotoniaInjectionDeviceNumber"],
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst &&
            data.estimationConst.hypotoniaInjectionDevicePrice,
          isEdit:
            dataStep1 && dataStep1.haveHypotoniaInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "hypotoniaInjectionDevicePrice"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.hypotoniaInjectionDevicePrice,
              data.estimationConst.hypotoniaInjectionDeviceNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.hypotoniaInjectionDeviceComment,
          key: KEY.REMARK,
          isEdit:
            dataStep1 && dataStep1.haveHypotoniaInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "hypotoniaInjectionDeviceComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "PH調整剤（酸）注入装置",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst &&
            data.estimationConst.phAdjusterInjectionDeviceNumber,
          keyInput: ["estimationConst", "phAdjusterInjectionDeviceNumber"],
          isEdit:
            dataStep1 && dataStep1.havePhAdjusterInjectionDevice === "1"
              ? true
              : false,
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst &&
            data.estimationConst.phAdjusterInjectionDevicePrice,
          isEdit:
            dataStep1 && dataStep1.havePhAdjusterInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "phAdjusterInjectionDevicePrice"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.phAdjusterInjectionDevicePrice,
              data.estimationConst.phAdjusterInjectionDeviceNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.phAdjusterInjectionDeviceComment,
          key: KEY.REMARK,
          isEdit:
            dataStep1 && dataStep1.havePhAdjusterInjectionDevice === "1"
              ? true
              : false,
          keyInput: ["estimationConst", "phAdjusterInjectionDeviceComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];
  const tableCost7 = [
    {
      title: "制御盤",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst && data.estimationConst.controlPanelNumber,
          isEdit:
            dataStep1 && dataStep1.haveControlPanel === "1" ? true : false,
          key: KEY.NUMBER_MACHINE,
          keyInput: ["estimationConst", "controlPanelNumber"],
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.controlPanelPrice,
          isEdit:
            dataStep1 && dataStep1.haveControlPanel === "1" ? true : false,
          keyInput: ["estimationConst", "controlPanelPrice"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              data.estimationConst.controlPanelPrice,
              data.estimationConst.controlPanelNumber
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.controlPanelPriceComment,
          key: KEY.REMARK,
          isEdit:
            dataStep1 && dataStep1.haveControlPanel === "1" ? true : false,
          keyInput: ["estimationConst", "controlPanelPriceComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];
  const tableCost8 = [
    {
      title: "試運転SV費",
      content: [
        {
          title: "数量(日)",
          content: data ? 2 + (data.numberOfMachines - 1) * 1 : "",
          key: KEY.NUMBER_MACHINE,
        },

        {
          title: "単価(千円)",
          content:
            data.estimationConst && data.estimationConst.commissioningSvFees,
          isEdit: true,
          keyInput: ["estimationConst", "commissioningSvFees"],
          key: KEY.PRICE_UNIT,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            exactMultiplication(
              2 + (data.numberOfMachines - 1) * 1,
              data.estimationConst.commissioningSvFees
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.commissioningSvFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "commissioningSvFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];
  const tableCost9 = [
    {
      title: "設計費",
      content: [
        {
          title: "数量(式)",
          content: data.estimationConst && data.estimationConst.designNumber,
          key: KEY.NUMBER_MACHINE,
          keyInput: ["estimationConst", "designNumber"],
        },

        {
          title: "金額(千円)",
          content: data.estimationConst && data.estimationConst.designFee,
          isShow: true,
          key: KEY.MONNEY,
          isEdit: true,
          keyInput: ["estimationConst", "designFee"],
        },

        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.designFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "designFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];
  const tableCost10 = [
    {
      title: "図書作成費",
      content: [
        {
          title: "数量(式)",
          content:
            data.estimationConst && data.estimationConst.bookCreationNumber,
          key: KEY.NUMBER_MACHINE,
          keyInput: ["estimationConst", "bookCreationNumber"],
        },

        {
          title: "金額(千円)",
          content: data.estimationConst && data.estimationConst.bookCreationFee,
          isShow: true,
          key: KEY.MONNEY,
          isEdit: true,
          keyInput: ["estimationConst", "bookCreationFee"],
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.bookCreationFeeComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "bookCreationFeeComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  // Equipment cost
  const equipmentCost = caculatorSumMoneyandWeight([
    tableCost1,
    tableCost2,
    tableCost3,
    tableCost4,
    tableCost5,
    tableCost6,
    tableCost7,
  ]);
  // Attach cost
  const attachCost = caculatorSumMoneyandWeight([
    tableCost2,
    tableCost3,
    tableCost4,
    tableCost5,
    tableCost6,
    tableCost7,
  ]);
  // Tổng phụ kiện kèm theo
  const attachEquimentCost = [
    {
      title: "付属機器小計",
      content: [
        {
          title: "質量(kg)",
          content: attachCost.weight,
        },
        {
          title: "金額(千円)",
          content: attachCost.money,
          isShow: true,
        },
      ],
    },
  ];
  // Machine bill
  const machineBill = [
    {
      title: "機器費計",
      content: [
        {
          title: "質量(kg)",
          content: equipmentCost.weight,
        },
        {
          title: "金額(千円)",
          content: equipmentCost.money,
          isShow: true,
        },
      ],
    },
  ];

  const totalCost = [tableCost8, tableCost9, tableCost10].reduce(
    (result, table) => {
      let money = 0;
      const moneyItem = table[0].content.find((i) => i.key === KEY.MONNEY);
      if (moneyItem) {
        money = Number(moneyItem.content);
      }
      return (result += money);
    },
    equipmentCost.money
  );
  const total = [
    {
      title: "計",
      content: [
        {
          title: "金額(千円)",
          content: totalCost,
          isShow: true,
        },
      ],
    },
  ];
  const reverseFund = [
    {
      title: "予備費",
      content: [
        {
          title: "料率(%)",
          content: data.estimationConst && data.estimationConst.reserveFund,
          isEdit: true,
          keyInput: ["estimationConst", "reserveFund"],
          key: KEY.PRICE_UNIT_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round((data.estimationConst.reserveFund * totalCost) / 100),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst && data.estimationConst.reserveFundComment,
          key: KEY.REMARK,
          isEdit: true,
          keyInput: ["estimationConst", "reserveFundComment"],
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
  ];

  const dcCost =
    data.estimationConst &&
    Math.round(
      exactMultiplication(
        Number(data.estimationConst.reserveFund) + 100,
        totalCost
      ) / 100
    );
  // DC
  const dc = [
    {
      title: "DC計",
      content: [
        {
          title: "金額(千円)",
          content: dcCost,
          isShow: true,
          key: KEY.MONNEY,
        },
      ],
    },
  ];

  // Chi phí quản lí
  const manageCost = [
    {
      title: "工事管理費",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst &&
            data.estimationConst.constructionManagementPercent,
          keyInput: ["estimationConst", "constructionManagementPercent"],
          isEdit: true,
          key: KEY.MANAGE_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.constructionManagementPercent * dcCost) /
                100
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.constructionManagementPercentComment,
          keyInput: ["estimationConst", "constructionManagementPercentComment"],
          isEdit: true,
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
    {
      title: "販売管理費",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst &&
            data.estimationConst.generalAdministrativePercent,
          keyInput: ["estimationConst", "generalAdministrativePercent"],
          isEdit: true,
          key: KEY.MANAGE_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round(
              (data.estimationConst.generalAdministrativePercent * dcCost) / 100
            ),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.generalAdministrativePercentComment,
          keyInput: ["estimationConst", "generalAdministrativePercentComment"],
          isEdit: true,
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
    {
      title: "手数料",
      content: [
        {
          title: "料率 (%)",
          content:
            data.estimationConst && data.estimationConst.commissionPercent,
          keyInput: ["estimationConst", "commissionPercent"],
          isEdit: true,
          key: KEY.MANAGE_PERCEN,
        },

        {
          title: "金額(千円)",
          content:
            data.estimationConst &&
            Math.round((data.estimationConst.commissionPercent * dcCost) / 100),
          isShow: true,
          key: KEY.MONNEY,
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.commissionPercentComment,
          keyInput: ["estimationConst", "commissionPercentComment"],
          isEdit: true,
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
  ];
  // Cost of sale
  const costOfSale = caculatorSumMoneyandWeight([dc, manageCost]);
  costOfSale.money = roundToDecimalPlaces(costOfSale.money, -3);

  const costOfSaleTable = [
    {
      title: "販売原価",
      content: [
        {
          title: "金額(千円)",
          content: costOfSale.money,
          isShow: true,
          key: "money",
        },
      ],
    },
  ];

  const profitAndLossper = data.estimationConst
    ? Number(data.estimationConst.profitLossPercent)
    : 0;
  const sellingPrice = roundToDecimalPlaces(
    (costOfSale.money * 100) / (100 - profitAndLossper),
    -3
  );
  //損益率：％（％）　金額：tiền（千円）
  const profitAndLoss = [
    {
      title: "損益 (P)",
      content: [
        {
          title: "損益率 (%)",
          content: profitAndLossper,
          isEdit: true,
          key: KEY.BENEFIT_LOSS_PERCEN,
          keyInput: ["estimationConst", "profitLossPercent"],
        },

        {
          title: "金額(千円)",
          content: sellingPrice - costOfSale.money,
          isShow: true,
          key: KEY.MONNEY,
          fullText:
            "損益率 : " +
            profitAndLossper +
            "(%)" +
            "    " +
            "金額 : " +
            formatCurrencyJPY(sellingPrice - costOfSale.money || 0) +
            "(千円)",
        },
        {
          title: lang.REMARK,
          content:
            data.estimationConst &&
            data.estimationConst.profitLossPercentComment,
          isEdit: true,
          rule: "all",
          ruleKeyDown: "all",
          key: KEY.REMARK,
        },
      ],
    },
  ];

  // Price sell Table
  const priceSellTable = [
    {
      title: "販売価格",
      content: [
        {
          title: "金額(千円)",
          content: sellingPrice,
          isShow: true,
        },
      ],
    },
  ];

  // Data Step4
  const dataStep4 = [
    {
      title: "機器名称",
      content: [
        {
          title: "機器名称",
          content: data.estimationConst && data.estimationConst.machineName,
          isEdit: true,
          keyInput: ["estimationConst", "machineName"],
          key: KEY.MONNEY,
          isShow: true,
          rule: "all",
          ruleKeyDown: "all",
        },
      ],
    },
    {
      title: "ろ過排水時間",
      content: [
        {
          title: "ろ過排水時間",
          content:
            data.estimationConst && data.estimationConst.filtrationDrainageTime,
          isEdit: true,
          keyInput: ["estimationConst", "filtrationDrainageTime"],
          key: KEY.MONNEY,
          isShow: true,
          unit: "分",
          rule: "interger",
          ruleKeyDown: "interger",
        },
      ],
    },
    {
      title: "NaCLO＿注入率（at有効塩素",
      content: [
        {
          title: "NaCLO＿注入率（at有効塩素）",
          content:
            data.estimationConst && data.estimationConst.nacloInjectionRate,
          isEdit: true,
          keyInput: ["estimationConst", "nacloInjectionRate"],
          key: KEY.MONNEY,
          isShow: true,
          unit: "mg/L",
          rule: "interger",
          ruleKeyDown: "interger",
        },
      ],
    },
    {
      title: "H2SO4＿薬品濃度",
      content: [
        {
          title: "H2SO4＿薬品濃度",
          content: data.estimationConst && data.estimationConst.h2so4DrugConc,
          isEdit: true,
          keyInput: ["estimationConst", "h2so4DrugConc"],
          key: KEY.MONNEY,
          isShow: true,
          unit: "％",
          rule: "interger",
          ruleKeyDown: "interger",
        },
      ],
    },
    {
      title: "H2SO4＿薬品比重",
      content: [
        {
          title: "H2SO4＿薬品比重",
          content:
            data.estimationConst &&
            data.estimationConst.h2so4ChemicalSpecificGravity,
          isEdit: true,
          keyInput: ["estimationConst", "h2so4ChemicalSpecificGravity"],
          key: KEY.MONNEY,
          isShow: true,
          unit: null,
          rule: "maxDecimalPlacesTwo",
          ruleKeyDown: "number",
        },
      ],
    },
    {
      title: "H2SO4＿注入率(at 100%)",
      content: [
        {
          title: "H2SO4＿注入率(at 100%)",
          content:
            data.estimationConst && data.estimationConst.h2so4InjectionRate,
          isEdit: true,
          keyInput: ["estimationConst", "h2so4InjectionRate"],
          key: KEY.MONNEY,
          isShow: true,
          unit: "mg/L",
          rule: "interger",
          ruleKeyDown: "interger",
        },
      ],
    },
  ];

  // Render Table Cost Collapse
  const rederCollapsetableCost = (
    arrData,
    num = 1,
    title = "",
    Icon,
    config = {}
  ) => {
    const isSingle = arrData.length === 1;
    const textTitle = title || arrData[0].title;
    const sigleEdit = isSingle && arrData[0].content.find((i) => i.isEdit);
    const { isOpen, isNotMoney } = config;
    let fullText;

    const price = arrData.reduce((result, item) => {
      const showEl = item.content.find((i) => i.isShow);
      if (showEl.fullText) {
        fullText = showEl.fullText;
      }
      const number = showEl ? (showEl.content ? showEl.content : 0) : 0;
      return result + number;
    }, 0);

    return (
      <Row gutter={0}>
        <Col span={24} className="mt8">
          <div style={{ margin: 0, padding: 0 }}>
            <Collapse
              defaultActiveKey={["1"]}
              className={isNotMoney ? "" : "collapseCost"}
              collapsible={arrData.length === 1 && "icon"}
              expandIconPosition={!isNotMoney ? "left" : "right"}
              style={{ padding: 0 }}
              expandIcon={
                !isNotMoney
                  ? ({ isActive }) =>
                      arrData.length === 1 ? null : isActive ? (
                        <DownOutlined
                          style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                        />
                      ) : (
                        <RightOutlined
                          style={{ left: 5, marginTop: sigleEdit ? 4 : 0 }}
                        />
                      )
                  : null
              }
            >
              <Panel
                header={
                  <>
                    <div
                      className="flex-center"
                      style={{
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        onClick={
                          isSingle
                            ? () => openModalInfoTableCost(arrData[0])
                            : null
                        }
                        className="flex-start"
                      >
                        {Icon ? (
                          <Icon
                            style={{
                              fontSize: 20,
                              color: "#0080cb",
                              marginRight: 6,
                              marginLeft: isSingle ? 8 : 0,
                            }}
                          />
                        ) : (
                          <IconNumber
                            marginLeft={isSingle ? 8 : 0}
                            size={20}
                            number={num}
                          />
                        )}
                        <span>{textTitle}</span>
                      </div>
                      <div
                        className="flex-start"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {sigleEdit && (
                          <EditOutlined
                            onClick={() => openModalEditTableCost(arrData[0])}
                            style={{
                              width: 30,
                              height: 30,
                              color: "#0080cb",
                              cursor: "pointer",
                            }}
                          />
                        )}
                        {!isNotMoney &&
                          (fullText ? (
                            <span>{fullText}</span>
                          ) : (
                            <span>{`${
                              !isSingle ? "金額 : " : ""
                            }${formatCurrencyJPY(price || 0)} (千円) `}</span>
                          ))}
                      </div>
                    </div>
                  </>
                }
                key={isOpen ? "1" : 0}
              >
                {arrData.length > 1 && (
                  <Descriptions size="small" column={1} bordered>
                    {renderItemTableCost(arrData, { isNotMoney })}
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
          </div>
        </Col>
      </Row>
    );
  };

  if (_.isEmpty(dataStep1) || _.isEmpty(dataStep2))
    return <Redirect to={path} />;
  return (
    <>
      <div
        style={{ marginTop: -20 }}
        className="des-content machine-content step3 valueInputRight"
      >
        <TitlePage name={"見積表の出力"}></TitlePage>

        <HeaderText text={LANG.PROJECT_INFOMATION} />
        <Row gutter={0}>{renderInfo(infoProjectArr, false)}</Row>
        <HeaderText text={LANG.CONDITION_INPUT} />
        <Row gutter={0}>{renderInfo(conditionArr, false)}</Row>
        <HeaderText text={LANG.TABLE_COSST} />
        <div className="wrapTableCost">
          {rederCollapsetableCost(tableCost1, 1, "本体機器小計")}
          {rederCollapsetableCost(tableCost2, 2)}
          {rederCollapsetableCost(tableCost3, 3)}
          {rederCollapsetableCost(tableCost4, 4)}
          {rederCollapsetableCost(tableCost5, 5)}
          {rederCollapsetableCost(tableCost6, 6, "薬品注入装置")}

          {rederCollapsetableCost(tableCost7, 7)}
          {rederCollapsetableCost(
            attachEquimentCost,
            1,
            "付属機器小計",
            ApiOutlined
          )}
          {rederCollapsetableCost(machineBill, 1, "機器費計", MenuOutlined)}

          {rederCollapsetableCost(tableCost8, 8)}
          {rederCollapsetableCost(tableCost9, 9)}
          {rederCollapsetableCost(tableCost10, 10)}
          {rederCollapsetableCost(total, 10, "計", FullscreenOutlined)}
          {rederCollapsetableCost(
            reverseFund,
            10,
            "予備費",
            MoneyCollectOutlined
          )}

          {rederCollapsetableCost(dc, 10, "DC計", ClockCircleOutlined)}
          {rederCollapsetableCost(manageCost, 11, "管理費")}
          {rederCollapsetableCost(
            costOfSaleTable,
            10,
            "販売原価",
            DollarOutlined
          )}
          {rederCollapsetableCost(
            profitAndLoss,
            10,
            "損益 (P)",
            VerticalAlignMiddleOutlined
          )}
          {rederCollapsetableCost(
            priceSellTable,
            10,
            "販売価格",
            EuroCircleOutlined
          )}
        </div>

        <HeaderText text={"設計計算書"} />

        {rederCollapsetableCost(
          dataStep4,
          6,
          "設計計算書",
          BookOutlined,

          {
            isOpen: true,
            isNotMoney: true,
          }
        )}
      </div>
      <Modal
        style={{ padding: 0 }}
        centered
        closeIcon={<></>}
        className=""
        title={dataModal ? dataModal.title : null}
        open={visible}
        onCancel={() => closeModalInfoTableCost()}
        footer={
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                className="buttonPC --todo-- wAuto  button--outline"
                onClick={() => closeModalInfoTableCost()}
              >
                {lang.CLOSE}
              </Button>
            </Col>
          </Row>
        }
      >
        <Descriptions size="small" column={1} bordered>
          {dataModal && renderDesContent(dataModal, { isOpenModal: false })}
        </Descriptions>
      </Modal>
      <Modal
        style={{ padding: 0 }}
        centered
        closeIcon={<></>}
        title={dataModal ? dataModal.title : ""}
        open={showModalEditPrice}
        onCancel={() => closeModalEditTableCost()}
        footer={
          <Row>
            <Col span={20} style={{ textAlign: "right" }}>
              <Button
                onClick={() => handleChangeValueCost()}
                className={"buttonOk"}
              >
                {LANG.OK}
              </Button>
              <Button
                className="buttonPC --todo-- wAuto  button--outline"
                onClick={() => closeModalEditTableCost()}
              >
                {lang.CANCEL}
              </Button>
            </Col>
          </Row>
        }
      >
        <Descriptions size="small" column={1} bordered>
          {dataModal && renderDesContent(dataModal, { isOpenModal: false })}
        </Descriptions>
      </Modal>

      <FooterPage>
        <Row>
          <Col span={12}>
            <div
              className={"actionRight"}
              onClick={() => {
                pushDataStep3();
                history.push(getPathEstimateWithStep(basePath, 2));
              }}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          <Col span={12}>
            <ButtonSave
              haveDraft={false}
              body={{
                ...cloneObj(formDataPostDefault),
                ...{ ...cloneObj(dataStep1), options: optionPost },
                estimationConst: data.estimationConst,
              }}
              handleOpenSave={() => dispatch(openSave())}
            />
          </Col>
        </Row>
      </FooterPage>
    </>
  );
}

export default AddStep3;
