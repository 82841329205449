import React from "react";
export const HeaderText = ({
  text,
  color = "black",
  size = 18,
  fontSize = 16,
}) => {
  return (
    <span
      style={{
        color,
        fontSize,
        fontWeight: "bold",
        position: "relative",
        display: "inline-block",
        marginBottom: 10,
        marginTop: 20,
      }}
      className="header-text"
    >
      {text}
    </span>
  );
};
