import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { COMMON_CONSTANT, KEY, lang } from "../../../constants/common.const";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import { Modal } from "antd";
import { EstimateApi } from "../../../api/backend/estimate";
function ButtonDelete({ isDraft = false }) {
  const history = useHistory();
  const { id } = useParams();
  const { version } = getQuerySearch();
  const deleteFunc = async () => {
    try {
      await EstimateApi.deleteEstimate(id, version);
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C004);
      history.push("/Estimate/list");
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    }
  };
  const handleDelete = () => {
    Modal.confirm({
      title: lang.DELETE,
      content: `Rev${version}の見積を取り消しますか？`,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: deleteFunc,
      centered: true,
    });
  };
  return (
    <div
      onClick={() => {
        handleDelete();
      }}
      className={"actionLeft"}
    >
      <DeleteOutlined
        className="mr4"
        style={{ fontWeight: "bold", fontSize: 16 }}
      />
      {"取消"}
    </div>
  );
}

export default ButtonDelete;
