import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { cloneObj } from "../utils/helpers";

const initialState = {
  loading:false,
  isInitEdit:true,
  openSaveButton:false,
  inputSearch: {
    customerName: "",
    constructionName: "",
    estimationNumber: "",
    createrId:"",
    rawWatterType:""
  },
  fromToStep2:"",
  dataStep1: {},
  dataStep2: {
    machineSelected:null,
    lv:{
      upperLimit:null,
      lowerLimit:null,
      current:null,
    }
  },
  dataFromStep2ToStep1:{
    fiberlengh:0,
    waterType:0,
    current:0,
    upperLimit:0,
    lowerLimit:0
  },
  dataStep3:{},
  dataDetail: {},
  optionsDefault:[],
  listLv:[],
  formDataPostDefault: {
    estimationNumber: "",
    customerName: "",
    constructionName: "",
    status: "",
    id: 0,
    rawWaterType: "",
    rawWaterDetailType: "",
    estimationConditionInput: {
      inputRawWaterVolume: "",
      inputRawWaterPh: "",
      inputRawWaterTurbidity: "",
      inputRawWaterSsConc: "",
      inputRawWaterIronConc: "",
      inputRawWaterMgConc: "",
      inputNetVolumeWaterRecovered: "",
      inputTreatedWaterTurbidity: "",
      inputOperatingTime: "",
    },
    fiberLength: "",
    completeSpareMachineQuantity: "",
    estimationAuxMachine: {
      haveAuxFiltrationPump: "",
      auxFiltrationPumpRegularNumber: "",
      auxFiltrationPumpBackupMachine: "",
      auxFiltrationPumpModel: "",
      auxFiltrationPumpCapacity: "",
      auxFiltrationPumpWeight: "",
      auxFiltrationPumpUnitPrice: "",
      haveAuxBackwashPump: "",
      auxBackwashPumpRegularNumber: "",
      auxBackwashPumpBackupMachine: "",
      auxBackwashPumpModel: "",
      auxBackwashPumpCapacity: "",
      auxBackwashPumpWeight: "",
      auxBackwashPumpUnitPrice: "",
      haveAuxAirWashingBlower: "",
      auxAirWashingBlowerRegularNumber: "",
      auxAirWashingBlowerBackupMachine: "",
      auxAirWashingBlowerModel: "",
      auxAirWashingBlowerCapacity: "",
      auxAirWashingBlowerWeight: "",
      auxAirWashingBlowerUnitPrice: "",
      haveAuxAirWashingBlowerSndprfBox: "",
      auxAirWashingBlowerSndprfBoxRegularNumber: "",
      auxAirWashingBlowerSndprfBoxBackupMachine: "",
      auxAirWashingBlowerSndprfBoxModel: "",
      auxAirWashingBlowerSndprfBoxWeight: "",
      auxAirWashingBlowerSndprfBoxUnitPrice: "",
    },
    estimationConst: {
      otherFees: "",
      instrumentationWorkFee: "",
      transportationFee: "",
      trialRunAdjustmentFee: "",
      attendanceFee: "",
      designDocumentPreparationFee: "",
      pacInjectionDeviceNumber: "",
      pacInjectionDevicePrice: "",
      hypotoniaInjectionDeviceNumber: "",
      hypotoniaInjectionDevicePrice: "",
      phAdjusterInjectionDeviceNumber: "",
      phAdjusterInjectionDevicePrice: "",
      controlPanelNumber: "",
      controlPanelPrice: "",
      commissioningSvFees: "",
      reserveFund: "",
      designNumber: "",
      designFee: "",
      bookCreationNumber: "",
      bookCreationFee: "",
    },
    options: [
      {
        optionId: "",
        optionName: "",
        order: "",
        value: "",
      },
    ],
    standardLv: "",
  },
  detailData:{}
  // lv:null
};

const estimateSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    setDataStep1: (state, { payload }) => {
      state.dataStep1 = payload;
    },
    setStepEstimate: (state, { payload }) => {
      state.step = payload;
    },
    setEstimateViewStep: (state, { payload }) => {
      state.stepView = payload;
    },
    setDataStep2: (state, { payload }) => {
      state.dataStep2 = payload;
    },
    setDataStep3: (state, { payload }) => {
      state.dataStep3 = payload;
    },
    setOptionDefault: (state, { payload }) => {
      state.optionsDefault = payload;
    },
    setFromToStep2:(state,{payload}) =>{
      state.fromToStep2 = payload
    },
    setDataFromStep2ToStep1:(state,{payload}) => {
     state.dataFromStep2ToStep1 = payload
    },
    resetState: (state) => {
      state.dataStep1 = {};
      state.dataStep2 = {
        machineSelected:null,
        lv:{
          upperLimit:null,
          lowerLimit:null,
          current:null,
        }
      };
      state.dataDetail = {};
      state.optionsDefault=[];
      state.fromToStep2 = "";
      state.listLv=[];
      state.dataFromStep2ToStep1 = {};
      state.isInitEdit = true;
      state.detailData = {}
    },
    setInputSearch: (state, { payload }) => {
      state.inputSearch = cloneObj(payload);
    },
    reSetInputSearch:(state,{payload}) => {
      state.inputSearch = cloneObj(initialState.inputSearch)
    },
    setDataConstant:(state,{payload}) => {
      state.formDataPostDefault.estimationConst = payload;
    },
    setListLv:(state,{payload}) => {
     state.listLv = payload;
    },
    openLoading:(state,{payload}) => {
      state.loading= true
    },
    closeLoading:(state,{payload}) => {
      state.loading= false
    },
    setInitEdit:(state,{payload}) => {
      state.isInitEdit= payload;
    },
    openSave:(state) => {
      state.openSaveButton= true;
    },
    closeSave:(state) => {
      state.openSaveButton= false;
    },
    setDetailData:(state,{payload}) => {
      state.detailData= payload;
    }
  },
});
// the action
export const {
  setDataStep1,
  setStepEstimate,
  setEstimateViewStep,
  resetState,
  setDataStep2,
  setInputSearch,reSetInputSearch,
  setDataConstant,
  setDataStep3,
  openLoading,
  closeLoading,
  setOptionDefault,
  setFromToStep2,
  setListLv,
  setDataFromStep2ToStep1,
  setInitEdit,
  openSave,
  closeSave,
  setDetailData
} = estimateSlice.actions;

// the selector
export const confirmSelector = (state) => state.estimate;

//the reducer
export default estimateSlice.reducer;
