import "../Estimate.scss";

import { RollbackOutlined, SendOutlined } from "@ant-design/icons";
import { Col, Collapse, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isIOS } from "react-device-detect";

import FooterPage from "@src/component/footer/footerPage";
import _ from "lodash";
import { Redirect, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { EstimateApi } from "../../../api/backend/estimate";
import BreakLine from "../../../component/Desktop/breakLine";
import RowInput from "../../../component/Desktop/rowInput";
import TitlePage from "../../../component/Desktop/titlePage";
import { KEY, REGEX_INPUT, STYLE } from "../../../constants/common.const";
import { LANG } from "../../../constants/lang.const";
import { MSG_CATCH, showMessage } from "../../../constants/utils";
import {
  openSave,
  setDataFromStep2ToStep1,
  setDataStep1,
  setDataStep2,
} from "../../../redux/estimate";
import {
  cloneObj,
  getPathEstimateWithStep,
  roundNumber,
} from "../../../utils/helpers";
import ButtonSave from "./buttonSave";
import { useMediaQuery } from "react-responsive";
import { ButtonModal } from "@src/page/Estimate/view/step2.jsx";
import ApiMachine from "@src/api/backend/machineManagement";
import DescriptionFiberLength from "../descriptionFiberLength";

const AddStep2 = ({ dataStep1, basePath }) => {
  const { Panel } = Collapse;
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const isAndroid = true;

  const { dataStep2, dataStep3, fromToStep2 } = useSelector(
    (state) => state.estimate
  );
  const { id } = useParams();
  const path = !id ? "/Estimate/Add" : "/Estimate/Edit/" + id;
  const [errLv, setErrorLv] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const isnotExistStep1Data = _.isEmpty(dataStep1);
  const [firberType, setFirberType] = useState("");
  const { formDataPostDefault } = useSelector((state) => state.estimate);
  const [lv, setLv] = useState("");
  const [actualLv, setActualLv] = useState("");
  const refLv = useRef();

  const [estimationAuxMachine, setEstimationAuxMachine] = useState({
    auxFiltrationPumpModel: "",
    auxBackwashPumpModel: "",
    auxAirWashingBlowerModel: "",
  });
  const [numberOfMachine, setNumberOfMachine] = useState("");
  const [machineSelected, setModelMachine] = useState("");
  const [netVolumeWaterRecovered, setNetVolumeWaterRecovered] = useState("");
  const volumnWater =
    dataStep1 && dataStep1.estimationConditionInput
      ? dataStep1.estimationConditionInput.inputRawWaterVolume ||
        dataStep1.estimationConditionInput.inputNetVolumeWaterRecovered
      : "";

  const getKbn = async () => {
    try {
      const [resFiberType, resLv] = await Promise.all([
        EstimateApi.getFirberType(),
        EstimateApi.getLv(),
      ]);
      const dataFiber = resFiberType.data.data;
      const item = dataFiber.find(
        (item) => item.key === dataStep1.rawWaterType
      );
      if (item) {
        setFirberType(item.value);
      }
    } catch (error) {}
  };
  const waterVolumTranfervalue = dataStep1.estimationConditionInput
    ? Math.round(
        (volumnWater * 10) /
          dataStep1.estimationConditionInput.inputOperatingTime
      ) / 10
    : 0;

  const validate = () => {
    if (
      dataStep2.lv.current > dataStep2.lv.upperLimit ||
      dataStep2.lv.current < dataStep2.lv.lowerLimit
    ) {
      refLv.current.focus();
    }

    return !errLv;
  };

  const handleNextStep = () => {
    if (!validate()) return;
    history.push(getPathEstimateWithStep(basePath, 3));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setLv(dataStep2.lv.current);
  }, [dataStep2.lv.current]);
  useEffect(() => {
    getKbn();
  }, [fromToStep2]);
  useEffect(() => {
    if (!_.isEmpty(dataStep1) && validate()) {
      let lvPost = String(dataStep2.lv.current);
      if (lvPost[lvPost.length - 1] === ".") {
        lvPost = lvPost.substring(0, lvPost.length);
      }
      EstimateApi.getDataStep3({
        ...dataStep1,
        standardLv: Number(lvPost),
      })
        .then((res) => {
          const data = res.data;
          setNumberOfMachine(data.numberOfMachines);
          setNetVolumeWaterRecovered(data.netVolumeWaterRecovered);
          setModelMachine(data.machineSelected);
          setActualLv(data.actualLv);
          setEstimationAuxMachine({
            ...estimationAuxMachine,
            auxFiltrationPumpModel: dataStep1.estimationAuxMachine
              .haveAuxFiltrationPump
              ? data.estimationAuxMachine.auxFiltrationPumpModel
              : "",
            auxBackwashPumpModel: dataStep1.estimationAuxMachine
              .haveAuxBackwashPump
              ? data.estimationAuxMachine.auxBackwashPumpModel
              : "",
            auxAirWashingBlowerModel: dataStep1.estimationAuxMachine
              .haveAuxAirWashingBlower
              ? data.estimationAuxMachine.auxAirWashingBlowerModel
              : "",
          });
          if (data.numberOfMachines) {
            const newDataStep1 = JSON.parse(JSON.stringify(dataStep1));
            newDataStep1.estimationAuxMachine.auxFiltrationPumpRegularNumber =
              data.numberOfMachines;
            dispatch(setDataStep1(newDataStep1));
          }
        })
        .catch(() => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {});
    }
  }, [dataStep2.lv.current]);

  // open save
  const handleOpenSave = () => {
    if (!validate()) return;
    dispatch(openSave());
  };
  // handle key down
  const hanldeKeyDown = (rule) => (e) => {
    if (
      rule &&
      !e.key.match(rule) &&
      e.key !== "Backspace" &&
      e.key !== "ArrowUp" &&
      e.key !== "ArrowDown" &&
      e.key !== "ArrowRight" &&
      e.key !== "ArrowLeft" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const [machineData, setMachineData] = useState({});
  useEffect(() => {
    if (machineSelected) {
      ApiMachine.getOne(machineSelected)
        .then((res) => {
          if (res.status === 200) {
            res.data = {
              ...res.data,
              ...res.data.eagleFiber,
              filtrationPumpModelNumber: res.data.filtrationPump.modelNumber,
              backwashPumpModelNumber: res.data.backwashPump.modelNumber,
              airWashingBlowerModelNumber:
                res.data.airWashingBlower.modelNumber,
            };
            setMachineData(res.data);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        });
    }
  }, [machineSelected]);

  return !isnotExistStep1Data ? (
    <>
      <div className="des-content wrapStep2 valueInputRight">
        <TitlePage name={"選定結果"}></TitlePage>
        <Collapse defaultActiveKey={["0"]} expandIconPosition="right">
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.PROJECT_INFOMATION_INPUT}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.WATER_TYPE}>
              {`${dataStep1.rawWaterTypevalue}`}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.FIBER_TYPE}
                  <br />
                  (型)
                </>
              }
              box
            >
              {`${firberType} ${firberType === "P" ? "(用水用)" : "(排水用)"}`}
            </RowInput>
            <BreakLine />

            <RowInput labelCol={8} name={LANG.FIBER_LENGTH} box>
              {dataStep1.fiberLength}㎜
              <DescriptionFiberLength filberLength={dataStep1.fiberLength} />
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.WATER_VOLUME}
                  <br />
                  (m³/d)
                </>
              }
              box
            >
              {volumnWater}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.OPERATING_TIME} box>
              {dataStep1.estimationConditionInput.inputOperatingTime}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {"処理水量"}
                  <br />
                  (m³/h)
                </>
              }
              box
            >
              {waterVolumTranfervalue}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.LV}
                  <br />
                  (m/h)
                </>
              }
              box
            >
              <Row>
                <Col
                  style={{ textAlign: "end", color: "gray", fontSize: 12 }}
                  span={24}
                >{`${dataStep2.lv.lowerLimit} - ${dataStep2.lv.upperLimit}`}</Col>
                <Input
                  ref={refLv}
                  className={errLv && STYLE.BORDER_RED}
                  type={isAndroid ? "number" : "text"}
                  onKeyDown={hanldeKeyDown(REGEX_INPUT.interger)}
                  value={lv}
                  onChange={(e) => {
                    const newvalue = e.target.value;
                    if (
                      newvalue !== "" &&
                      !newvalue.match(REGEX_INPUT.interger)
                    ) {
                      return;
                    }
                    const notErrorr =
                      newvalue >= dataStep2.lv.lowerLimit &&
                      newvalue <= dataStep2.lv.upperLimit;

                    setLv(newvalue);
                    if (notErrorr) {
                      dispatch(
                        setDataStep2({
                          ...dataStep2,
                          lv: { ...dataStep2.lv, current: newvalue },
                        })
                      );
                    }
                    setErrorLv(!notErrorr);
                  }}
                />
              </Row>
            </RowInput>

            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {"実質LV"}
                  <br />
                  (m/h)
                </>
              }
              box
            >
              {actualLv}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.NESSCES_AREA}
                  <br />
                  (m²)
                </>
              }
              box
            >
              {dataStep2.lv.current && !errLv
                ? roundNumber(waterVolumTranfervalue / dataStep2.lv.current, 3)
                : ""}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              box
              name={
                <>
                  {LANG.NET_VOLUMN_OF_WATER_RECOVERED}
                  <br />
                  (m³/d)
                </>
              }
            >
              {netVolumeWaterRecovered}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.SELECTED_RESULT}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.MODEL}>
              {machineSelected && (
                <ButtonModal
                  name={machineSelected}
                  type="machine"
                  machineData={machineData}
                />
              )}{" "}
              {machineData &&
                machineData.eagleFiber &&
                machineData.eagleFiber.eagleFiberTowerDimension}
            </RowInput>
            <BreakLine />
            <RowInput
              labelCol={8}
              name={
                <>
                  {LANG.QUANTITY}
                  <br />
                  (基)
                </>
              }
              box
            >
              {numberOfMachine}
            </RowInput>
          </Panel>
        </Collapse>
        <Collapse
          style={{ marginTop: 20 }}
          defaultActiveKey={["0"]}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div className={"wrapTitle"}>
                <span style={{ fontWeight: "bold" }}>
                  {LANG.AUXILIARI_MACHINE}
                </span>
              </div>
            }
            key={"0"}
          >
            <RowInput labelCol={8} box name={LANG.FILTRATION_PUMP}>
              {estimationAuxMachine.auxFiltrationPumpModel &&
                machineData.filtrationPump && (
                  <ButtonModal
                    id={machineData.filtrationPump.modelNumber}
                    name={machineData.filtrationPump.filtrationPumpModel}
                    version={machineData.filtrationPump.recordVersion}
                    type="pump"
                  />
                )}{" "}
              {estimationAuxMachine.auxFiltrationPumpModel &&
                machineData &&
                machineData.filtrationPump &&
                machineData.filtrationPump.filtrationPumpCapacity}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.BACKWASH_PUMP} box>
              {estimationAuxMachine.auxBackwashPumpModel &&
                machineData.backwashPump && (
                  <ButtonModal
                    id={machineData.backwashPump.modelNumber}
                    name={machineData.backwashPump.backwashPumpModel}
                    version={machineData.backwashPump.recordVersion}
                    type="pump"
                  />
                )}{" "}
              {estimationAuxMachine.auxBackwashPumpModel &&
                machineData &&
                machineData.backwashPump &&
                machineData.backwashPump.backwashPumpCapacity}
            </RowInput>
            <BreakLine />
            <RowInput labelCol={8} name={LANG.AIR_WASHING_BLOWER} box>
              {estimationAuxMachine.auxAirWashingBlowerModel &&
                machineData.airWashingBlower && (
                  <ButtonModal
                    id={machineData.airWashingBlower.modelNumber}
                    name={machineData.airWashingBlower.airWashingBlowerModel}
                    version={machineData.airWashingBlower.recordVersion}
                    type="air"
                  />
                )}{" "}
              {estimationAuxMachine.auxAirWashingBlowerModel &&
                machineData &&
                machineData.airWashingBlower &&
                machineData.airWashingBlower.airWashingBlowerCapacity}
            </RowInput>
          </Panel>
        </Collapse>
      </div>
      <FooterPage>
        <Row>
          <Col span={8}>
            <div
              className={"actionRight"}
              onClick={() => {
                dispatch(
                  setDataFromStep2ToStep1({
                    fiberLength: dataStep1.fiberLength,
                    waterType: dataStep1.rawWaterType,
                    current: dataStep2.lv.current,
                    upperLimit: dataStep2.lv.upperLimit,
                    lowerLimit: dataStep2.lv.lowerLimit,
                  })
                );
                history.push(getPathEstimateWithStep(basePath, 1));
              }}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          <Col span={8}>
            <ButtonSave
              body={{
                ...cloneObj(formDataPostDefault),
                ...cloneObj(dataStep1),
                ...dataStep3,
              }}
              handleOpenSave={handleOpenSave}
            />
          </Col>
          <Col span={8}>
            <div onClick={() => handleNextStep()} className={"actionRight"}>
              <SendOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              見積表の出力
            </div>
          </Col>
        </Row>
      </FooterPage>
    </>
  ) : (
    <Redirect to={path} />
  );
};
export default AddStep2;
