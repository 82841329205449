import {
  EditOutlined,
  RollbackOutlined,
  SaveOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";
import { Wind2 } from "iconsax-react";
import {
  Input,
  Button,
  Col,
  Row,
  Descriptions,
  Collapse,
  Timeline,
  Typography,
  Drawer,
  Modal,
  Select,
  Tooltip
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import TitlePage from "../../component/Desktop/titlePage";
import Loading from "../../component/Desktop/loading/loading";
import { lang, COMMON_CONSTANT, KEY } from "../../constants/common.const";
import ApiPump from "@src/api/backend/pump";
import {
  MSG_CATCH,
  showMessage,
  getQuerySearch,
} from "../../constants/utils";
import BoxContent from "@src/component/Desktop/content";
import "./machine.scss";
import { isArray } from "lodash";
import { LANG } from "../../constants/lang.const";
import FooterPage from "@src/component/footer/footerPage";
import { isEmpty } from "lodash";
import { QuestionToolTip } from "./air.jsx";
import { UploadFileSpace } from './view';
import ApiMachine from "../../api/backend/machineManagement";
import { useSelector } from "react-redux";

const { Panel } = Collapse;
const { Text } = Typography;

function ViewMachine({ isEndUser }) {
  const history = useHistory();
  const { id } = useParams();
  const { version } = getQuerySearch();
  const isUpdate = id && history.location.pathname.includes("update");
  const isView = id && !history.location.pathname.includes("update");
  const isCreate = !id && history.location.pathname.includes("create");
  const { isLeader } = useSelector((state) => state.auth);
  const [showLoading, setShowLoading] = useState(false);

  const [machineData, setMachineData] = useState({});
  useEffect(() => {
    if (isView || isUpdate) {
      setShowLoading(true);
      ApiPump.getOne(id, version == 'update' ? '' : version)
        .then((res) => {
          if (res.status === 200) {
            setMachineData(res.data);
          }
        })
        .catch((err) => {
          showMessage(KEY.ERROR, MSG_CATCH());
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, [id]);

  const save = async () => {
    const body = {
      "id": machineData.id,
      "modelNumber": machineData.modelNumber,
      "model": machineData.model,
      "type": machineData.type,
      "motorPower": machineData.motorPower,
      "flow": +machineData.flow,
      "lift": +machineData.lift,
      "motorPoles": +machineData.motorPoles,
      "materialCasing": machineData.materialCasing,
      "materialImpeller": machineData.materialImpeller,
      "materialShaft": machineData.materialShaft,
      "shaftSeal": machineData.shaftSeal,
      "waterInjection": machineData.waterInjection,
      "partition": +machineData.partition,
      "weight": +machineData.weight,
      "stdUnitPrice": +machineData.stdUnitPrice,
      "attachFiles": listAttachMachine.map((item, index) => {
        return {
          "fileName": item.fileName,
          "fileUrl": item.fileUrl,
          "order": index,
          "note": item.note,
          "recordVersion": 0,
        }
      }),
    }

    const api = isCreate ? ApiPump.save(body) : ApiPump.update(body);
    try {
      setShowLoading(true);
      const res = await api;
      showMessage(KEY.INFO, COMMON_CONSTANT.BOM_C003);
      history.push(`/pump/${machineData.modelNumber}`);
    } catch (e) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      setShowLoading(false);
    }
  };

  const [invalid, setInvalid] = useState([]);

  const handleSaveData = () => {
    Modal.confirm({
      centered: true,
      title: lang.CONFIRM,
      content: id ? COMMON_CONSTANT.BOM_C009 : COMMON_CONSTANT.BOM_C008,
      okText: lang.OK,
      cancelText: lang.CANCEL,
      onOk: () => { save() },
    });
  };

  const [listAttachMachine, setListAttachMachine] = useState([]);

  const downloadFile = async () => {
    try {
      setShowLoading(true);
      const thisType = machineData.type == 1 ? 2 : 1;
      const resApi = ApiMachine.downloadFile(machineData.modelNumber || "", thisType, '', version);
      const res = await resApi;
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = `${machineData.modelNumber || 'file'}.zip`;
      link.click();
    } catch (e) {
      let msg = MSG_CATCH();
      if (e.response && e.response.status == 404) {
        msg = "ファイルが見つかりません";
      }
      showMessage(KEY.ERROR, msg);
    } finally {
      setShowLoading(false);
    };
  };

  return (
    <>
      <div className="des-content machine-content">
        <TitlePage name={isView ? LANG.VIEW_MACHINE : (id ? LANG.UPDATE_MACHINE : LANG.ADD_MACHINE)} />
        <Loading show={showLoading} />
        <Row gutter={0} style={{}}>
          <Col span={24} className="mt8">
            <DetailMachine machineData={machineData} setMachineData={setMachineData} />
          </Col>
          <Col span={24} className="mt8">
            {(!isEmpty(machineData) || isCreate) && <UploadFileSpace listAttachMachine={listAttachMachine} setListAttachMachine={setListAttachMachine}
              isView={isView} setShowLoading={setShowLoading} type={machineData.type == 1 ? 2 : 1}
              version={version} id={id}
            />}
          </Col>
        </Row>
        <div style={{ height: "100px" }}></div>
      </div>
      <FooterPage>
        <Row style={{ cursor: 'pointer' }}>
          {isView && <Col span={isLeader ? 8 : 12}>
            <div
              className={isLeader ? "actionRight" : "actionLeft"}
              style={{ borderRight: "1px solid #e8e8e8" }}
              onClick={() => downloadFile()}
            >
              <CloudDownloadOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {lang.DOWNLOAD_PDF}
            </div>
          </Col>}
          <Col span={isView && isLeader ? 8 : 12}>
            <div
              className={"actionCancel"}
              style={{ borderRight: "1px solid #e8e8e8" }}
              onClick={() => history.push(`/machine/list?tab=2`)}
            >
              <RollbackOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />{" "}
              {LANG.BACK}
            </div>
          </Col>
          {isView && isLeader && <Col span={8}>
            <div
              onClick={() => history.push(`/pump/${id}/update`)}
              className={"actionLeft"}
            >
              <EditOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              {lang.EDIT}
            </div>
          </Col>}
          {!isView && <Col span={12}>
            <div
              onClick={handleSaveData}
              className={"actionLeft"}
            >
              <SaveOutlined
                className="mr4"
                style={{ fontWeight: "bold", fontSize: 16 }}
              />
              {lang.SAVE}
            </div>
          </Col>}
        </Row>
      </FooterPage>
    </>
  );
}

export default ViewMachine;

export const DetailMachine = ({ id: idProp, viewMode = false, machineData, setMachineData, version }) => {
  const history = useHistory();
  let { id } = useParams();
  id = idProp || id;
  const isUpdate = !viewMode && id && history.location.pathname.includes("update");
  const isView = viewMode || (id && !history.location.pathname.includes("update"));
  const isCreate = !viewMode && !id && history.location.pathname.includes("create");

  const machine_information_display = [
    {
      key: 'modelNumber',
      title: "機種番号",
      content: machineData.modelNumber,
      isRequired: true,
      auto: true,
    },
    {
      key: 'type',
      title: "タイプ",
      content: machineData.type == 1 ? "ろ過" : "逆洗",
      isSelect: true,
      options: [
        { value: 1, label: 'ろ過' },
        { value: 2, label: '逆洗' }
      ],
      isRequired: true,
    },
    {
      key: 'flow',
      title: (<>流量 <br />(㎥/h)</>),
      content: machineData.flow,
      isRequired: true,
      inputType: 'number',
    },
    {
      key: 'lift',
      title: (<>揚程<br />(m)</>),
      content: machineData.lift,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
      isInteger: true,
    },
    {
      title: "電動機",
      content: [
        {
          key: 'motorPower',
          title: "kW",
          content: machineData.motorPower,
          isRequired: true,
          inputType: 'number',
        },
        {
          key: 'motorPoles',
          title: "極数",
          content: machineData.motorPoles,
          isRequired: true,
          inputType: 'number',
        },
      ],
      inlineDisplay: true,
    },
    {
      title: "材質",
      content: [
        {
          key: 'materialCasing',
          title: "ケーシング",
          content: machineData.materialCasing,
          isSelect: false,
          options: [].map((item) => {
            return {
              value: item.optionName,
              label: item.optionName,
            };
          }),
          isRequired: true,
        },
        {
          key: 'materialImpeller',
          title: "インペラ",
          content: machineData.materialImpeller,
          isSelect: false,
          options: [].map((item) => {
            return {
              value: item.optionName,
              label: item.optionName,
            };
          }),
          isRequired: true,
        },
        {
          key: 'materialShaft',
          title: "シャフト",
          content: machineData.materialShaft,
          isSelect: false,
          options: [].map((item) => {
            return {
              value: item.optionName,
              label: item.optionName,
            };
          }),
          isRequired: true,
        },
      ],
      inlineDisplay: true,
    },
    {
      key: 'shaftSeal',
      title: <>軸封</>,
      content: machineData.shaftSeal,
      inlineDisplay: true,
      isSelect: false,
      options: [].map((item) => {
        return {
          value: item.optionName,
          label: item.optionName,
        };
      }),
      isRequired: true,
    },
    {
      key: 'waterInjection',
      title: <>注水</>,
      content: machineData.waterInjection,
      inlineDisplay: true,
      isRequired: true,
    },
    {
      key: 'model',
      title: <>型式</>,
      content: machineData.model,
      inlineDisplay: true,
      isRequired: true,
    },
    {
      key: 'weight',
      title: (<>重量 <br /> (kg)</>),
      content: machineData.weight,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
    },
    {
      key: '',
      title: (<><QuestionToolTip title={"流量(㎥/h) x 揚程(m) x 電動機(kW)"} /> 能力</>),
      content: (machineData.flow && machineData.lift && machineData.motorPower) && `${machineData.flow}㎥/h x ${machineData.lift}m x ${machineData.motorPower}kW`,
      inlineDisplay: true,
      auto: true,
    },
    {
      key: 'partition',
      title: (<>仕切</>),
      content: machineData.partition,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
    },
    {
      key: 'stdUnitPrice',
      title: (<>基準単価<br />(千円)</>),
      content: machineData.stdUnitPrice,
      inlineDisplay: true,
      isRequired: true,
      inputType: 'number',
    },
    {
      key: '',
      title: (<><QuestionToolTip title={"基準単価(千円) x 仕切"} /> 単価<br />(千円)</>),
      content: (<>{Math.ceil(machineData.stdUnitPrice * machineData.partition) || 0}</>),
      inlineDisplay: true,
      auto: true,
    },
  ];

  const onChangeInput = (e, info) => {
    let { value } = e.target;
    const newmMchineData = { ...machineData };

    // remove character not numeric when inputType is number
    if (info.inputType === "number") {
      value = value.replace(/[^0-9.]/g, "");
      if (value.split(".").length > 2) {
        value = value.substring(0, value.length - 1);
      }
    }

    newmMchineData[info.key] = value;
    setMachineData(newmMchineData);
  };

  const onChangeSelect = (value, info) => {
    const newmMchineData = { ...machineData };
    newmMchineData[info.key] = value;
    setMachineData(newmMchineData);
  };

  const Content = <Descriptions size="small" column={1} bordered>
    {machine_information_display.map((info, index) => {
      return (
        <Descriptions.Item
          span={1}
          key={index}
          label={<>{info.title} {(info.isRequired && !isView) && <span className="mark-required">*</span>}</>}
          labelStyle={{
            width: "35%",
            fontWeight: "bold",
            padding: "8px 8px",
          }}
          contentStyle={{
            padding: !isArray(info.content) ? "8px" : "0px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!isArray(info.content) && isView &&
            (info.content || "-")}
          {!isArray(info.content) && !isView && (<>
            {
              !info.isSelect && !info.auto && <Input value={info.content} onChange={(e) => { onChangeInput(e, info) }}
              // type={info.inputType || 'text'}
              />
            }
            {
              info.isSelect && <Select className="w100" options={info.options} value={info.content} onChange={(e) => onChangeSelect(e, info)} />
            }
            {
              info.auto && <Text>{info.content}</Text>
            }
          </>
          )}
          {isArray(info.content) && (
            <Descriptions
              key={index + '1'}
              size="small"
              column={1}
              bordered
            >
              {info.content.map((info, index) => {
                return (
                  <Descriptions.Item
                    key={index}
                    span={1}
                    label={<>{info.title} {(info.isRequired && !isView) && <span className="mark-required">*</span>}</>}
                    labelStyle={{
                      width: "45%",
                      fontWeight: "semi-bold",
                    }}
                  >
                    {isView && (info.content || "-")}
                    {!isView && <>
                      {
                        !info.isSelect && !info.auto && <Input onChange={(e) => { onChangeInput(e, info) }} value={info.content}
                        // type={info.inputType || 'text'}
                        />
                      }
                      {
                        info.isSelect && <Select className="w100" options={info.options} value={info.content} onChange={(e) => onChangeSelect(e, info)} />
                      }
                      {
                        info.auto && <Text>{info.content}</Text>
                      }
                    </>}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          )}
        </Descriptions.Item>
      );
    })}
  </Descriptions>;

  const [listAttachMachine, setListAttachMachine] = useState([]);
  if (viewMode) {
    return <>
      {Content}
      <div className="mt8 machine-content">
        <UploadFileSpace listAttachMachine={listAttachMachine} setListAttachMachine={setListAttachMachine}
          isView={isView} setShowLoading={() => { }} type={machineData.type == 1 ? 2 : 1}
          version={version || 0} id={machineData.modelNumber} onlyTable={true}
        />
      </div>
    </>
  }

  return <>
    <BoxContent style={{ margin: 0, padding: 0 }}>
      <Collapse
        defaultActiveKey={["1"]}
        expandIconPosition="right"
      >
        <Panel header={<><Wind2 className="first-icon" />ポンプ機種表</>} key="1">
          {Content}
        </Panel>
      </Collapse>
    </BoxContent>
  </>;
};
