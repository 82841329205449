export const INSPECTION_TIME = [
  { id: "1", name: "日" },
  { id: "2", name: "週" },
  { id: "3", name: "月" },
  { id: "4", name: "年" },
  { id: "5", name: "その他" },
];
export const TYPE_INPUT = {
  NUMBER: "10000",
  TEXT: "01000",
  SELECT: "00100",
  RATIO: "00010",
  TEXT_AREA: "00001",
};
export const COMMON_CONSTANT = {
  INPUT_FORMAT_NUM: "数字",
  INPUT_FORMAT_CHAR: "文字",
  INPUT_FORMAT_CHAR_1: "テキスト",
  INPUT_FORMAT_CHOI: "選択",
  INPUT_FORMAT_CHOI_1: "選択肢",
  INPUT_FORMAT_YESNO: "無/有",
  INSPECTION_TIMING_OTHER: "その他",
  // BOM_I001: "データがありません。",
  ADD_USER: "ユーザーの追加に成功しました。",
  UPDATE_USER: "権限を編集します。よろしいですか?",
  UPDATED_USER: "ユーザー権限の更新に成功しました。",
  DELETE_USER: "ユーザーの権限を排除します。よろしいですか？",
  DELETED_USER: "ユーザーの除外に成功しました。",
  BOM_I002: "アイテムが既に点検表に追加されています。",
  BOM_I003: "機器が既に追加されています。",
  BOM_C001: "削除してもよろしいですか？", // Delete Are you sure you want to?
  BOM_C002: "正常に取り込まれました。",
  BOM_C003: "正常に保存しました。", //"Saved successfully.",
  BOM_C004: "正常に削除しました。", // "Deleted successfully.",
  BOM_C005: "正常に出力されました。",
  BOM_C006: "保存してもよろしいですか？", // Are you sure you want to save it?
  BOM_C008: "入力内容を登録してもよろしいですか？", //Are you sure you want to register the input contents?
  BOM_C009: "入力内容を更新してもよろしいですか？", //Are you sure you want to update your entry?
  BOM_C013: "この点検表を承認してもよろしいですか？", // Are you sure you want to approve this checklist?
  BOM_C018:
    "いくつかの値が変更されています。このページから離れてもよろしいですか？", // Some values have changed. Are you sure you want to leave this page?
  BOM_C019: "この点検表を拒否してもよろしいですか？",
  BOM_C021: "点検頻度を変更してもよろしいですか？", // Are you sure you want to change the inspection frequency?
  BOM_C022: "選択したレコードに変更があるため、操作が完了しませんでした。",
  BOM_C030:
    "選択した点検表は既に{user}さんによってアクセスされています。後でもう一度お試しください。",
  BOM_C032: "この機器を削除してもよろしいですか？",
  BOM_C033: "このグループを削除してもよろしいですか？",
  BOM_C035:
    "点検表にデータが既に入力されているため、この操作を実施できません。",
  BOM_C038: "この点検表を承認解除してもよろしいですか？",
  BOM_C039: "権限を変更します。よろしいですか?",
  BOM_E003:
    "ファイルフォーマットが無効です。正しいフォーマットを選択してください。",
  BOM_E004:
    "インターネットに接続されていません。接続してから再度お試しください。", //Bạn không kết nối với Internet. Vui lòng kết nối và thử lại.
  BOM_E005: "出力するデータが存在していません。",
  BOM_E006: "ファイル内容が無効です。他のファイルを選択してください。",
  BOM_E007: "タイムアウトしました。もう一度お試しください。", // Hết giờ
  BOM_E008: "サーバーエラーです。もう一度お試しください。", // Server error
  BOM_E009: "入力したデータは既にシステムに存在しています。",
  BOM_E015: "カメラの起動に失敗しました。",
  BOM_E018:
    "部品コードの名前は既にシステムに存在しています。名前を変更して再度お試しください。",
  BOM_E021:
    "「出庫数量」フィールドには「在庫数量」フィールドの値より高い値を入力できません。値を変更して再度お試しください。",
  BOM_E022:
    "日付のフィールドには今日以前の日付を入力しなければなりません。再度お試しください。",
  BOM_E023: "有効期限は投稿日以降の日付を入力してください。",
  BOM_E025:
    "「製造年月日」フィールドには「入庫日」フィールドの値より高い値を入力できません。値を変更して再度お試しください。",
  BOM_E026:
    "「製造年月日」フィールドには「出庫日」フィールドの値より高い値を入力できません。値を変更して再度お試しください。",
  BOM_E027:
    "機器分類マスターの名前は既にシステムに存在しています。名前を変更して再度お試しください。",
  BOM_E028: "点検区分を変更してもよろしいですか？", // bạn có muốn thay đổi phân loại kiểm chứng không?
  BOM_E029: "サーバーエラーのため失敗しました。",
  BOM_E030: "入力データが無効です。再チェックしてください。", // Invalid input data. Please check again.
  BOM_E031: "全ての入力必須フィールドにデータを入力していません。",
  BOM_E032: "出庫数量は在庫数量以下でなければなりません。",
  BOM_E033: "入庫数量は0より大きい値でなければなりません。",
  BOM_E034: "出庫数量は0より大きい値でなければなりません。",
  EMPTY_DATA: "データがありません。",
  EDIT_OPTION: "編集",
  VIEW_OPTION: "閲覧",
  DELETE_OPTION: "削除",
  CUSTOM_LIST_CHECK_ITEM: "CheckItem",
  WORK_REPORT_STATUS_OPENED: "オープン",
  WORK_REPORT_STATUS_REJECTED: "拒否済",
  WORK_REPORT_STATUS_RESOLVED: "解決済",
  WORK_REPORT_STATUS_CONFIRMED: "確認済",
  WORK_REPORT_STATUS_APPROVED: "承認済",
  WORK_REPORT_STATUS_REOPENED: "再開済",
  NEW_DATA_TEMPLATE_CODE: "#{0}#HD{1}",
  SPECIAL_CHARACTER: {},

  REJECTED: "REJECTED",
  CONFIRMED: "CONFIRMED",
  APPROVED: "APPROVED",
  RESOLVED: "SOLVED",

  SAVE_DRAFT: "一時保存",
  SAVE: "保存 ",
  CLEAR: "キャンセル",
  TITLE_UPDATE_GROUP: "グループを更新",
  TITLE_CREATE_GROUP: "グループを追加",
  TITLE_DEL_GROUP: "グループ削除",
  GROUP_NAME: "グループ名",
  GROUP_TYPE: "グループ種類",
  CLOSE: "閉じる",
  ADD: "追加 ",
  UPDATE: "保存 ",
  BACK: "戻る",
  GROUP_NAME_EXISTS: "このグループ名は存在する。",
  WARNING_SETTING_CHIEF_ROLE: "所長権限を設定する場合は、情報システムGの承認が必要です。",
  ASSIGN_BY_ME: "自分作成",
  ASSIGN_BY_CHIEF: "所長作成",
};

export const lang = {
  UPLOAD: "アップロード",
  ADMIN_GROUP: "グループ管理者",
  GROUP_COMMON: "共通",
  GROUP_USER: "個人",
  NEWEST_VERSION: "最新バージョン",
  OFFICIAL: "正式",
  DRAFT: "下書",
  ADDED: "追加された",
  NOT_ADDED: "追加されませんでした",
  MACHINES: "機種",
  INPUT: "入力",
  FACILITY: "施設",
  BUILDING: "棟",
  FLOOR: "階",
  ROOM: "部屋",
  PROCESS: "プロセス",
  RESET: "リセット",
  MACHINE_ONLY: "機種のみ",
  REMARK_ITEM: "備考項目",
  SENDER: "送信元",

  NAME_SYSTEM: "FSシステム",
  LIST_CHECKLIST: "点検表登録",
  INFO_CHECKLIST: "点検情報",
  CREATE_CHECKLIST: "点検表作成",
  CLONE_CHECKLIST: "点検表参照作成",
  EDIT_CHECKLIST: "点検表情報更新",
  HISTORY_CHECKLIST: "点検履歴",
  SAMPLE_TEMP_MANAGEMENT: "報告テンプレート管理",
  EXPORT_REPORT: "報告書出力",
  IMPORT_CSV: "CSVファイル入力",
  WORK_REPORT_MANAGEMENT: "作業報告管理",
  ROLE_SETTING: "ユーザ設定",
  REGISTRY_ORIGIN_DATA: "機種表インポート",
  CL_MANAGEMENT: "点検管理",
  REPORT_MANAGEMENT: "報告管理",
  REGISTRY_MACHINE: "機器台帳登録",
  REGISTRY_INVENTORY: "倉庫マスターデータ登録",
  OFFICE_DETAIL: "事業所情報",
  EDIT_OFFICE_DETAIL: "事業所情報更新",
  OFFICE_MANAGEMENT: "事業所情報管理",
  CREATE_OFFICE_DETAIL: "事業所情報登録",
  SC_REST_LAST_VALUE: "総累計リセット",
  DYNAMIC_REPORT_MANAGEMENT: "動的レポート管理",

  INVENTORY: "在庫",
  IMPORT_INVENTORY: "入庫レコード",
  IMPORT_INVENTORY_UPDATE_BUTTON: "更新",
  IMPORT_INVENTORY_ADD: "入庫レコード追加",
  IMPORT_INVENTORY_UPDATE: "入庫レコード更新",
  IMPORT_INVENTORY_VIEW: "入庫レコード詳細",
  EXPORT_INVENTORY: "出庫レコード",
  EXPORT_INVENTORY_UPDATE_BUTTON: "更新",
  EXPORT_INVENTORY_ADD: "出庫レコード追加",
  EXPORT_INVENTORY_UPDATE: "出庫レコード更新",
  EXPORT_INVENTORY_VIEW: "出庫レコード詳細",

  WAREHOUSES_MASTER: "倉庫",
  WAREHOUSES_NAME: "倉庫名称",
  WAREHOUSES_CODE: "倉庫コード",
  CATEGORY_MASTER: "カテゴリー",
  CATEGORY_NAME: "カテゴリー名",
  CATEGORY_CODE: "カテゴリーコード",
  ITEM_MASTER: "項目",
  ITEM_NAME: "項目名",
  ITEM_CODE: "項目コード",
  INVENTORY_DATA: "在庫数量",
  STOCK: "在庫履歴",

  AUTO_EXPORT_INVENTORY: "出庫取引自動作成",
  TRADING_CODE: "取引コード",
  STORAGE: "倉庫",
  IMPORTER: "入庫者",
  IMPORT_DATE: "入庫日",
  EXPORTER: "出庫者",
  EXPORT_DATE: "出庫日",
  CREATE_DATE: "作成日",
  UPDATE_DATE: "更新日",
  CREATOR: "作成者",
  UPDATER: "更新者",
  ITEM_TYPE: "項目種別",
  STOCK_QUANTITY: "在庫数量",
  MINIMUM_STOCK_QUANTITY: "最小在庫数量",
  MANUFACTURER: "メーカー",
  MAKER_MODEL_NUMBER: "型式",
  SAMPLES: "例",
  NUMBER: "本数",
  RECEIVING_UNIT: "入荷計算単位",
  INVENTORY_UNIT: "在庫計算単位",
  INVENTORY_QUANTITY: "入庫数量",
  CONVERSION_RATE: "換算率",
  TYPE_OF_MACHINE_USED: "使用機器種類",
  RETAILER: "購入先",
  ORDER_NUMBER: "伝票数",
  REMARKS: "記入",
  INVENTORY_INFORMATION: "在庫情報",
  SHOW_ONLY_ALERTED_ITEMS: "警報項目のみ表示",
  SHOW: "示す",
  READ_MORE: "もっと見る",
  ENDING_STOCK_QUANTITY: "期末在庫数量",
  BEGINING_STOCK_QUANTITY: "期首在庫数量",
  STOCK_OUT_QUANTITY: "出庫レコード",
  STOCK_IN_QUANTITY: "入庫レコード",
  QUANTITY_IMPORT: "入荷数量",
  QUANTITY_EXPORT: "出庫数量",
  UNIT_PRICE: "単価",
  AMOUNT: "金額",
  TOTAL: "合計",
  ADD_MACHINE: "機器追加",

  NEW: "新規",
  DATE: "日",
  WEEK: "週",
  MONTH: "月",
  YEAR: "年",
  COLON: "：",
  TODO_CHECKLIST: "点検実施",
  BOOKMARK: "ブックマーク",
  CHECK_RESULT: "進行中",
  FILTER: "フィルタ",
  CHECKLIST: "点検",
  DELETE_GROUP: "グループを削除",
  EDIT_GROUP: "グループエディター",
  ADD_TO_GROUP: "グループを追加",
  CHECKLIST_NAME: "点検書式名",
  CHECKLIST_TYPE: "点検表種類",
  ALL_CHECKLIST: "すべて",
  ACTION: "アクション",
  SELECT: "選択 ",
  OK: "OK",
  ERROR: "失敗",
  SUCCESS: "成功",
  WARMING: "注意",
  SETTING: "表示設定",
  ADD: "新規作成", // Create
  ADD_CHECKLIST: "点検追加",
  CANCEL: "キャンセル",
  SEARCH: "検索 ",
  // EMPTY: "データがありません。",
  CLOSE: "閉じる",
  CLEAR: "クリアー",
  EXPORT: "ファイル出力",
  DELETE: "削除",
  DELETE_ALL: "全て削除",
  APPROVE: "承認 ",
  UNAPPROVE: "承認解除",
  REJECT: "拒否 ",
  ATTACH: "関連図面ファイル",
  BACK: "戻る",
  DYNAMIC_REPORT: "データグラフ確認",
  LOCATION_MACHINE: "位置情報",
  REASON_FOR_REJECT: "拒否の理由",
  REQUIRED: "必須",
  EDIT: "編集 ",
  VERSION_UP: "バージョンアップ",
  VIEW: "閲覧",
  CLONE: "複製",
  CONFIRM: "確認",
  APPROVED: "承認済",
  UNAPPROVED: "未承認",
  RESOLVE: "解決 ",
  LIST_VERSION: "バージョン一覧",
  VERSION: "ver.",
  SAVE: "保存",
  DISCARD: "廃棄",
  ALL: "全て",
  SEE_ALL: "全て表示",
  EXPEND_ALL: "すべて展開",
  ID: "ID",
  NUMBER_ORDER: "No",
  REPORT_NAME: "報告名",
  REPORT_DATE: "報告日",
  REPLENISHMENT: "補充量",
  ERROR_PATTERN: "属性の形式と一致していません。",
  SUPPORT_CHECK_PERSON: "補助点検者",
  PREVIEW: "プレビュー",
  FILE_NAME: "ファイル名",
  REMARKS_AND_MEDIA_INSERTION_OPERATION: "備考とメヂイアの挿入と操作",
  ITEM: "項目",
  TIME: "日時",
  INSPECTOR: "担当",
  SAVE_NORMAL: "保存",
  SAVE_DRAFT: "一時保存",
  SAVE_DRAFT_REVUP: "一時保存",
  SAVE_REPORT: "作業報告",
  REPAIR_METHOD: "改修方法",
  SAVE_TROUBLE_REPORT: "トラブル報告",
  REPORT: "報告",
  REPORT_LEVEL: "報告レベル",
  PRIORITY: "優先度",
  CHECKLIST_SCREEN: "点検画面",
  REPORT_STATUS: "トラブル報告処理状況",
  EQUIPMENT_NAME: "機器名",
  CL_OVERVIEW: "点検実施状況",
  SELECT_GROUP: "グループを選択",
  NUMBER_GROUP: "グループの数",
  TYPE_CHECKLIST: "点検頻度",
  REMARK_TYPE: "備考タイプ",
  STATE: "状態",
  STATUS: "ステータス",
  REJECT_REASON: "理由拒否",
  STATUS_MACHINE: "機種ステータス",
  PERSON_IN_CHARGE: "担当者",
  DELETE_BOOKMARK: "削除ブックマーク",
  ADD_BOOKMARK: "ブックマック追加",
  DELETE_HOMEPAGE: "ホームページ削除",
  ADD_HOMEPAGE: "ホームページ追加",
  COMMON: "共通",
  WARNING_SAVE_NORMAL:
    "入力したデータにて、運転めやす範囲を超える数字がありますが、「正常保存」してもよろしいですか？",
  WARNING_HAS_NONE_INPUT:
    "未点検の点検項目があり、そのまま他の画面に遷移しますか？",
  DETAIL_CHECKLIST_TITLE: "点検詳細",
  INCOMPLETE: "未完成",
  EDITOR: "更新者",
  TIMES: "回目",
  MORE_TIMES: "複数回",
  TIME_CHECKING: "点検時刻",
  BEGIN_CHECK: "点検開始日時",
  END_CHECK: "点検完了日時",
  HISTORY_RECORD: "記録履歴",
  MAIN_CHECKER: "主記録者",
  CHECK_TIMES: "チェック回数",
  TIMES_NOT_VALUE: "回目の点検項目しない",
  NOTE: "備考",
  PHOTO: "写真",
  COMMENT: "コメント",
  ADD_IMAGE: "写真を追加する",
  PREVIOUS_VALUE: "前回の値",
  RANGE: "範囲",
  REFER_VALUE_PREVIEW: "値を記入",
  VALUE: "値",
  NO: "無",
  YES: "有",
  HIGH: "高",
  MIDDLE: "中",
  LOW: "低",
  REGISTRY_CHECKLIST: "点検表登録",
  NAME_SHEET: "シート名",
  NUMBER_ROW: "行番号",
  MESSAGE: "メッセージ",
  PLEASE_SELECT_SITE: "サイトオフィスを選択してください!",
  PLEASE_CHOOSE_FILE: "ファイルを入力してください!",
  DO_YOU_WANT_TO_REGISTRY: "入力内容を登録してもよろしいですか",
  PLEASE_INPUT_NAME_TEMPLATE: "テンプレート名を入力してください",
  PLEASE_INPUT_CATEGORY: "カテゴリーを入力してください",
  TEMPLATE_MUST_IS_EXCEL_FILE:
    "テンプレートはExcelファイルである必要があります!",
  INFO_TEMPLATE_REPORT: "報告テンプレート情報",
  NAME_OF_TEMPLATE_HAS_EXISTED:
    "テンプレートの名前は既にシステムに存在しています",
  GROUP_CL_HAS_EXISTED: "グループ名は既に存在しています",
  REGISTRY_TEMPLATE: "報告テンプレート登録",
  EDIT_TEMPLATE: "報告テンプレート編集",
  TEMPLATE_CODE: "テンプレートコード",
  SELECT_FILE: "インポートファイル選択",
  NAME_OFFICE_SITE: "事業所名",
  EXPORT_REPORT_CHECKLIST: "点検情報出力",
  EXPORT_REPORT_INVENTORY: "倉庫マスターデータ出力",
  EXPORT_MASTER_DATA: "マスタデータ出力",
  EXPORT_OFFICE_SITE_DATA: "事業所サイトデータ出力",
  EXPORT_TEMPLATE_CL: "テンプレート出力",
  DYNAMIC_CHART: "動的レポート",
  IMPORT_MASTER_DATA: "マスタデータ登録",
  IMPORT_OFFICE_SITE_DATA: "事業所サイトデータ登録",
  IMPORT_TEMPLATE_CL: "ファイル入力",
  REGISTRY: "登録",
  JUDGEMENT: "判定",
  LIST_BOOKMARK: "ブックマーク一覧",
  LIST_CHECK_RESULT: "実行中一覧",
  HOMEPAGE: "ホームページ",
  HOME_BANNER: "お知らせ",
  SAVE_CL_DRAFT: "に既に保存されていますが、結果を統合しますか。",
  SAVE_CL_CRAFT_WITH_NO_MERGE:
    "によって既に保存されています。点検データを上書きしますか？（既に保存されている点検データは削除されます）",
  CREATED: "作成する ",
  DO_YOU_WANT_TO_OVERRIDE: "上書きしてもよろしいでしょうか？",
  OPERATION_MANAGEMENT: "運転管理",
  LOG_OUT: "ログアウト",
  LIST_GROUP_CHECKLIST: "点検表一覧",
  LIST_TARGET_CHECKLIST: "点検対象一覧",
  COPYRIGHT:
    "Copyright © Sumitomo Heavy Industries Environment Co.,Ltd. All Rights Reserved.",
  TEMPLATE_NAME: "テンプレート名",
  APPROVER: "承認者",
  INSPECTION_DATE: "点検日付",
  INSPECTION_PERSON: "点検者",
  SHEET: "シート",
  CATEGORY: "カテゴリー",
  OTHER_DATE: "日付",
  REGISTRATION_DATE: "登録日付",
  SEARCH_KEYWORD: "検索キーワード",
  EDITING_DATE: "更新日時",
  TARGET_DATE: "対象日付",
  TARGET_WEEK: "対象週付",
  TARGET_MONTH: "対象年月",
  TARGET_YEAR: "対象年度",
  ACCESS_NUMBERS: "該当件数",
  BEGIN_DATE_TO_END_DATE: "開始日 ～ 終了日",
  END_DATE_CHECK_CHECKLIST: "点検終了日時",
  CASE: "件",
  PLEASE_INPUT_NAME: "名前を入力してください",
  INPUT_FIELD_DO_NOT_SPECIAL_CHAR: "データ入力は特殊文字を許可しません",
  CURRENT_CHECKLIST_IS_PROCESSING: "実施中の点検画面が前のページに戻ります。",
  DO_YOU_SAVE_DRAFT_CHECKLIST: "入力されたデータを保存しますか？",
  SAVE_DRAFT_CL: "◎下書保存：データは「進行中」に保存されます。",
  CANCEL_SAVE_CL: "◎キャンセル：入力したデータは保存されません。",
  CHECKLIST_HAS_ADDED_IS_NOT_DISPLAYED_IN_RESULT:
    "※検査表に追加されている点検対象は検索結果に表示させません。",
  CAN_NOT_DOWNLOAD_DATA_NOT_EXISTED:
    "指定した日付は以下のデータが存在しない為、ダウンロードが出来ません。もう一度ご確認ください。",
  LOADING: "読み込み中...",
  CONNECTED: "接続済み",
  DISCONNECTED: "インターネット接続なし",
  SEND: "送信中",
  UNSENT: "未送信点検表",
  DOWNLOAD: "ダウンロード",
  REJECT_CHECKLIST_SUCCESS: "正常に拒否しました。",
  CONFIRM_CHECKLIST_SUCCESS: "正常に確認しました。",
  APPROVE_CHECKLIST_SUCCESS: "正常に承認しました。",
  SOLVED_NORMALLY: "正常に解決しました。",
  WORK_REPORT_INFORMATION: "作業報告情報",
  REPORT_INFO: "報告情報",
  CHECK_RESULT_DESTINATION: "点検表結果先",
  CONFIRMER_MAIL_ADDRESS: "確認者のメールアドレス",
  APPROVED_MAIL_ADDRESS: "承認者のメールアドレス",
  PART_NAME: "部品名",
  QUANTITY: "数量",
  PRECAUTION_TROUBLE_DETAIL: "注意事項・トラブル内容",
  NO_INDEX: "連番",

  ACCUMULATED_VALUE: "総累計",
  CURRENT_VALUE: "現在の値",
  ADD_COMMENT: "コメントを追加",
  PLEASE_UPLOAD_WHEN_HAS_INTERNET:
    "インターネットが不安定なエリアのため写真のアップロードができません。インターネットが安定しているエリアで再度アップロードして下さい。",

  // home banner
  HOME_BANNER_MANAGEMENT: "お知らせ管理",
  HOME_BANNER_INFORMATION: "お知らせ情報",
  HOME_BANNER_CREATE: "お知らせ登録",
  HOME_BANNER_UPDATE: "お知らせ更新",
  SEE_MORE: "もっと見る",
  TITLE: "タイトル",
  TYPE: "種類",
  CONTENT: "内容",

  //function-machine-management
  FUNCTION_CUSTOMIZATION_SETTINGS: "機能カスタマイズ設定",
  FUNCITION_NAME: "機能名",
  FUNCTION_ID: "機能ID",
  OFFICE: "事業所",
  ALL_FACTORY: "全事業所",
  FUNCTION_PARENT_NAME: "親メニュー",
  MACHINE_MANAGEMENT: "機器台帳管理",

  //machine-management
  MACHINE_NAME: "機器名",
  MACHINE_MANUFACTURE_NAME: "メーカー名",
  MACHINE_STATUS: "ステータス",
  MACHINE_STATUS_VALUE: [
    {
      key: 1,
      value: "稼働中",
    },
    {
      key: 2,
      value: "停止中",
    },
    {
      key: 3,
      value: "休止中",
    },
    {
      key: 4,
      value: "故障中",
    },
    {
      key: 5,
      value: "修理中",
    },
    {
      key: 6,
      value: "廃棄",
    },
  ],
  MACHINE_INSTITUTION: "施設",
  MACHINE_ADD: "機器台帳新規作成",
  VIEW_MACHINE: "",
  EDIT_MACHINE: "機器台帳更新",
  NAME_MACHINE: "機器名",
  NAME_FACILITY: "施設名",
  NAME_BUILDING: "棟名",
  NAME_FLOOR: "階名",
  NAME_ROOM: "部屋名",
  NAME_PROCESS: "プロセス名",
  MODEL: "型式",
  SERI_NUMBER: "シリアル番号",
  SET_TIME: "設置年月",
  SPECIFICATIONS: "主仕様",
  SERVICE_LIFE: "耐用年数",
  DRAWING_NUMBER: "図面明細番号",
  MADE: "製番",
  USE: "用途",
  SERVICE_LIFE_IN_HOUSE: "耐久年数（自社）",
  YEAR_OF_MANUFACTURE: "製造年度",
  DUABILITY: "耐用時間",
  REPLACEMENT_DISPOSAL_YEAR: "取替廃棄年月",
  CONDITION: "コンディション",
  TITLE_POPUP_EDITMACHINE: "更新履歴",
  DATE_REGISTER_MACHINE: "登録日付",
  MACHINE_CODE: " 機器コード",
  MACHINE_LEDGER_INFOMATION: "機器台帳情報",
  MACHINE_LEDGER_UPDATE: "機器台帳更新",
  MACHINE_LEDGER_ADD: "機器台帳新規作成",
  MACHINE_IMPORT: "機器台帳一括作成",
  REMARK: "備考",
  UPDATE_TYPE: "更新種類",
  UPDATE_CONTENT: "更新内容",
  ATTACH_FILE: "関連図面ファイル",
  ATTACH_NOTE: "※ファイルサイズの上限は100MBです。",
  PROJECT: "項目",
  INSPECTION_ITEM: "点検項目",
  INSPECTION_TIME_NAME: "点検項目名",
  CONTROL_VALUE_LOW: "管理値(下限値)",
  CONTROL_VALUE_UPPER: "管理値(上限値)",
  EASY_DRIVE_LOW: "運転めやす(下限値)",
  EASY_DRIVE_UPPER: " 運転めやす(上限値)",
  INVENTORY_LINKAGE: "在庫連携",
  INVENTORY_ITEM: "在庫項",
  QUANTITY_LEFT_IN_WAREHOUSE: "倉庫残量",
  USED_QUANTITY: "使用済数量",
  TYPE_MACHINE: "種類",
  QUANTITY_MACHINE_STOCK: "在庫数量へ",
  INPUT_FORMAT: "入力形式",
  UNIT: "単位",
  SELECTION: "選択",
  LETTER: "文字",
  INSPECTION_FREQUENCY: "点検頻度",
  CHANGE_LOG: "更新履歴",
  CHANGE_DATE: "変更日付",
  DEVICE_LEDGER_HISTORY: "機器台帳履歴",
  MAINTAINCE_HISTORY: "保全履歴",
  MAINTAINCE_DATE: "実施年月日",
  MAINTAINCE_DIVISION: "施工業者",
  MAINTAINCE_PIC: "区分",
  MAINTAINCE_OVERVIEW: "概要",
  MAINTAINCE_WORK_CONTENT: "作業内容",
  MAINTAINCE_TEMPLATE: "テンプレート出力",
  REPAIR_PLAN: "修繕計画",

  //location management
  LOCATION_MANAGEMENT: "機器台帳",
  FACILITY_MANAGEMENT: "施設管理",
  FACILITY_MANAGEMENT_INFOMATION: "施設管理情報",
  PROCESS_MASTER_NAME: "プロセスマスタ名",
  PROCESS_CONTROL_INFOMATION: "プロセス管理情報",
  PROCESS_MANAGEMENT_REGISTRATION: "プロセス管理登録",
  PROCESS_MANAGEMENT_UPDATE: "プロセス管理更新",
  PROCESS_MANAGEMENT: "プロセス管理",
  COMPLETION_DATE: "竣工年月日",
  ADDRESS: "住所",
  APPLICABLE_START_DATE: "適用開始日",
  APPLICABLE_END_DATE: "適用終了日",
  FACILITY_MANAGER: "施設責任者",
  POST_CODE: "郵便番号",
  NAME_OF_PREFECTURE: "都道府県名",
  PROCESSING_METHOD: "処理方式",
  PROCESSING_POWER: "処理能力",
  SITE_MANAGEMENT: "場所管理",
  
  FACILITY_MANEGEMENT_INFO_UPDATE: "施設管理情報更新",
  FACILITY_MANEGEMENT_INFO_ADD: "施設管理情報登録",
  PLANT_NAME: "機種名",
  MESS_CONFIRM_SAME_BUILDING_NAME: "棟が重複されています。再入力してください。",
  MESS_CONFIRM_SAME_FLOOR_NAME: "階が重複されています。再入力してください。",
  MESS_CONFIRM_SAME_ROOM_NAME: "部屋が重複されています。再入力してください。",

  MANY_ATTACH: "一括ファイル出力",
  EVERYONE: "全員",
  DESIGNATED_PERSON: "指定",
  USER_NAME: "ユーザ名",
  RECIPIENT: "受信者",

  // NEW pre-use checklist
  PATROL_INSPECTION_CHECKLIST: "巡視点検表",
  PRE_USE_CHECKLIST: "使用前点検表",

  CALENDAR: "点検カレンダー",
  TASK_NAME: "タスク名",
  REMIND: "リマインダー",
  // User management
  VIEW_USER: "VIEW_USER",
  DISPLAYNAME_USER: "表示名",
  EMAIL_USER: "メールアドレス",
  STAMP_USER: "判子",
  USER_INFORMATION: "ユーザ情報",
  AVATAR_USER: "写真",
  EDIT_USER: "ユーザー情報の編集",
  CROP_IMAGE: "写真のトリミング",
  DOWNLOAD_PDF:"ダウンロード",

  //Calibration management
  CALIBRATION_MANAGEMENT: "計測機器管理",
  ADD_NEW_DEVICE: "計測機器作成",
  VIEW_DEVICE: "計測機器閲覧",
  EDIT_DEVICE: "計測機器更新",
  CALIBRATION_HISTORY: "校正履歴",
  MANAGER: "担当者",
  OWNER: "機器所有者",
  MANUFACTURE_NAME: "メーカー名",
  RATING: "定格",
  MODEL_NUMBER: "型式",
  SN: "シリアル番号",
  FIRST_REGIST_DATE: "初回登録日",
  CALIBRATION_CERT_NUMBER: "校正証明書番号",
  CALIBRATION_CYCLE: "校正周期",
  INSTRUMENT_NAME:"計測機器名",
  CALIBRATION_RECORD_ITEM_LIST:"校正記録項目一覧",
  CALIBRATION_RECORD_ITEMS:"校正記録項目",
  RECORD_TIMELINE:"記録タイムライン",
  ROLES:"権限",
  FACTORY_MAME:"事業所",
  STANDARD_VALUE:"基準値",
  ADD_REPORT: "報告新規作成",
  EDIT_REPORT: "報告更新",
  GROUP: "グループ",
  LOWER_VALUE: "運転めやす (下限値)",
  UPPER_VALUE: "運転めやす (上限値)",
};

export const KEY = {
  GROUP_TYPE_COMMON: "1",
  GROUP_TYPE_USER: "2",
  LAPTOP_WIDTH: "(min-device-width: 1000px)",
  PORTRAIT_MOBILE_WIDTH: "(max-width: 500px)", // rotate screen in mobile
  NOTI: "NOTI",
  ERROR: "ERROR",
  INFO: "INFO",
  CONFIRM: "CONFIRM",
  WARMING: "WARMING",
  DISABLED: "DISABLED",
  NUMBER: "Number",
  TEXT: "Text",
  DDL: "DropDownList",
  CHECKBOX: "Checkbox",
  TEXTAREA: "TEXTAREA",
  DATEPICKER: "DATEPICKER",
  IMPORT: 1,
  EXPORT: 2,
  SORT_TABLE: "SORT_TABLE",
  IMAGE: "image",

  APPROVE: "0",
  CUSTORMER_APPROVE:"6",
  UNAPPROVE: "1",
  REJECT: "2",
  WORKING: "3",
  DONE: "4",

  // Setting menu
  LOC_USER_INFO: "uInfo",
  USER_ROLE: "userRole",
  MENU_ADMIN_OFFICE: "2", // office admin
  MENU_ADMIN: "1", // head office

  // Format date
  DATE_DEFAULT: "YYYY/MM/DD",
  WEEK: "YYYY-WW",
  MONTH: "YYYY/MM",
  YEAR: "YYYY",
  MONTH_DATE: "MM/dd",
  MONTH_DATE_MOMENT: "MM/DD",
  DATE_WITHOUT_HOUR: "yyyy/MM/dd",
  DATE_WITH_HOUR: "yyyy/MM/dd HH:mm",
  DATE_WITH_HOUR_DESKTOP: "YYYY/MM/DD HH:mm",
  HOUR_24H: "HH:mm",
};

export const STYLE = {
  BORDER_RED: "border-red",
};

export const CONFIG = {
  paging: { position: ["bottomCenter"], pageSize: 15, hideOnSinglePage: false },
};

export const LOCATION_CHART = {
  DYNAMIC_CHART_MANAGEMENT: "DYNAMIC_CHART_MANAGEMENT",
};
export const LIMIT = 30;
export const PAGE_SIZE = 15;

// Kich thuoc anh can cat
export const SIZE_IMAGE = 300;

export const ROLE_KEY = {
  ADMIN: '1',
  OPERATOR: '11',
  CHIEF: '2',
  MEMBER: '3',
  REGULAR_APPROVER: '4',
  SPECIAL_READING: '5',
}

export const  ESTIMATE_STEP = {
  STEP1:"STEP1",
  STEP2:"STEP2",
  STEP3:"STEP3"
}
export const ESTIMATE_VIEW_STEP = {
  STEP1:"STEP1",
  STEP2:"STEP2",
  STEP3:"STEP3"
}
export const REGEX_INPUT = {
  interger : /^\d+$/,
  intergerFrom0To :(max) =>{
    const regexString = `^(?:[0-9]|[1-9][0-9]{1,2}|${max})$`;
    return new RegExp(regexString);
  },
   generateDecimalRegex:(maxDecimalPlaces)  => {
    const regexString  =`^(?:\\d+(\\.\\d{1,${maxDecimalPlaces}})?|\\d+\\.)$`
    return new RegExp(regexString)
  },
  number:/^[0-9]*\.?[0-9]*$/,
  intergerFrom1To :/^([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)$/,
  intergerFrom1To100 :/^([1-9]|[1-9][0-9]|100)$/,
  intergerFrom1To10 :/^([1-9]|10)$/,

  all:null,
  
  get maxDecimalPlacesTwo (){ return this.generateDecimalRegex(2)},
}
