import React from "react";
import { Select } from "antd";
const { Option } = Select;

export const renderOption = (options, optionBlank = true, lengthText) => {
  if (options && options.length > 0) {
    return (
      <>
        {optionBlank && <Option value="" key="blank"></Option>}
        {options.map((e) => {
          let optionText = e.value || e.name;
          if (lengthText && typeof lengthText === "number") {
            if (optionText.length > lengthText) {
              optionText = optionText.substring(0, lengthText) + "...";
            }
          }
          return (
            <Option value={e.key || e.id} key={e.key || e.id}>
              {optionText}
            </Option>
          );
        })}
      </>
    );
  }
};
// get path with step
export const getPathEstimateWithStep = (basePath, step) => {
  const index = basePath.indexOf("step");
  const index2 = basePath.indexOf("&step")
  const startQuery = basePath.indexOf("?");
  if(startQuery < 0){
    basePath = basePath+"?"
  }
  if(index<0){
    return basePath + ((index2 < 0  & startQuery>0)? "&" :"")+ "step=" + step;
  }
  else{
    basePath = basePath.substring(0,index + 5) + step + basePath.substring(index+6);
    return basePath;
  }
};

// Tinh so chu so sau dau phay
function countDecimalPlaces(number) {
  const stringNumber =
    number != null && number !== undefined && !isNaN(number)
      ? number.toString()
      : "0";
  const decimalIndex = stringNumber.indexOf(".");

  if (decimalIndex === -1) {
    return 0; // số nguyên
  } else {
    return stringNumber.length - decimalIndex - 1;
  }
}
// Nhan chinh xac
export const exactMultiplication = (...arg) => {
  if (arg.findIndex((i) => isNaN(i)) >= 0) {
    return 0;
  }
  if (arg.findIndex((i) => !i) >= 0) return 0;
  let count = 0;
  let arr = [...arg];
  arr = arr.map((item) => {
    const countDecimal = countDecimalPlaces(item);
    if (countDecimal > 0) {
      count += countDecimal;
      return item * Math.pow(10, countDecimal);
    }
    return item;
  });
  const multi = arr.reduce((result, item) => {
    return result * item;
  }, 1);
  return multi / Math.pow(10, count);
};

// format money
export function formatCurrencyJPY(amount) {
  if(isNaN(amount)){
    return amount;
  }
  const formattedAmount = amount
    ? Number(amount).toLocaleString("ja-JP", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 10,
      })
    : 0;

  return formattedAmount ? formattedAmount.replace("¥", "") : 0; // Loại bỏ ký hiệu tiền tệ
}

export const roundNumber = (number, n) => {
  return Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
};

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));
export const isEmptyData = (data) =>
  data == null || data === "" || data === "undefined";
// calcNetVolumeWaterRecovered
export function calcNetVolumeWaterRecovered(
  rawWaterVolume,
  eagleFiberArea,
  operatingTime,
  rawWaterSsConc,
  numberOfMachines
) {
  const C15 = 40;
  const C19 = 12;
  const C18 = 20;
  const C20 = 5;
  const I3 = 6;

  const C12 = (rawWaterVolume / numberOfMachines / operatingTime).toFixed(1);
  const I2 = (C12 * rawWaterSsConc) / 1000;
  const C14 = Math.round((I3 / I2) * 60);
  const C16 = (eagleFiberArea * C15).toFixed(1);
  const C17 = ((C16 * C19) / 60).toFixed(1);
  const C22 = C14 + C18 + C20;
  const C23 = ((operatingTime * 60) / C22).toFixed(1);
  const C25 = (C17 * C23).toFixed(1);
  const C21 = ((C12 * C20) / 60).toFixed(1);
  const C27 = (C21 * C23).toFixed(1);

  return rawWaterVolume - C25 - C27;
}

// get String QuerySearch

export const getStringQuery = (obj) => {
  let str = "";
  Object.keys(obj).forEach((key) => {
    if (obj[key]) str += `&${key}=${obj[key]}`;
  });
  return str;
};

// Round up

export const roundToDecimalPlaces = (number, decimalPlaces) => {
  if (isNaN(number) || isNaN(decimalPlaces)) {
    return 0;
  }
  number = Number(number);
  decimalPlaces = Number(decimalPlaces);
  const factor = Math.pow(
    10,
    decimalPlaces >= 0 ? decimalPlaces : -decimalPlaces
  );
  if (decimalPlaces >= 0) {
    return Math.ceil(number * factor) / factor;
  } else {
    const integerNumber = Number.parseInt(number);
    return (
      Number.parseInt(
        Number.isInteger(integerNumber / factor)
          ? integerNumber / factor
          : integerNumber / factor + 1
      ) * factor
    );
  }
};
// Tính tổng weight và money các table
export const caculatorSumMoneyandWeight = (listTable) => {
  const result = {
    weight: 0,
    money: 0,
  };

  listTable.forEach((table) => {
    table.forEach((i) => {
      const { content } = i;
      content.forEach((contentItem, index) => {
        if (contentItem.key && contentItem.key.toLowerCase() === "weight") {
          result.weight += contentItem.content;
        }
        if (contentItem.key && contentItem.key.toLowerCase() === "money") {
          result.money += contentItem.content;
        }
      });
    });
  });
  return { ...result };
};
