import { AuthenticationContext, adalGetToken } from "react-adal";
import { KEY } from "@src/constants/common.const";
export const config = {
  backendEndpoint: "https://directcostmng.azurewebsites.net/",
  defaultEndpoint: "https://directcostmng.azurewebsites.net/",
  clientEndpoint: "https://fss.shi-ev.com/",
  clientId: "8fe99dc9-e2ac-4043-8293-6391b6349b33",
  timeoutDefault: 600000,
};
export const adalConfig = {
  tenant: "adfs",
  clientId: config.clientId,
  redirectUri: config.clientEndpoint,
  postLogoutRedirectUri: config.clientEndpoint,
  instance: "https://evgfs.shi.co.jp/",
  endpoints: {
    api: config.defaultEndpoint,
  },
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

async function getNewToken() {
  let token = authContext.getCachedToken(adalConfig.clientId);
  if (token === null) {
    token = await adalGetToken(authContext, adalConfig.clientId);
  }
  return token;
}

export const getToken = getNewToken;

export const getUser = () => authContext.getCachedUser();

export const logOut = () => {
  const idToken = localStorage.getItem("adal.idtoken");
  if (idToken !== null && idToken !== "") {
    localStorage.removeItem("siteOffice");
    localStorage.removeItem("displayName");
    localStorage.removeItem(KEY.USER_ROLE);
    authContext.logOut();
  } else {
    localStorage.clear();
    window.location.replace(config.clientEndpoint);
  }
};

export const toyotaKamoId = 35001;
export const arrayClInputDate = [1122, 1956];
export const yuniId = 10019;
export const workingSaveStatus = [2, 3]