import { Avatar, Col, Row, Select, Flex } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Status } from "../../components/common/status";
import { KEY } from "../../constants/common.const";
import "./Estimate.scss";

const ItemSearch = ({ item, hanldeDeleteItem, toEditScreen, toViewScreen }) => {
  const sortVersion = item.versionsData.sort((a, b) => b.version - a.version);
  const optionVersion = sortVersion.map((i) => ({
    key: i.version,
    value: `Rev.${i.version}/${i.updater}(${
      i.status == "完了" ? i.status : "見積中"
    })`,
  }));
  let avatarChar = item.createName || "";
  if (avatarChar) {
    avatarChar = avatarChar[0].toUpperCase();
  }
  const isPC = useMediaQuery({
    query: KEY.LAPTOP_WIDTH,
  });
  const currentVersion = item.versionsData[0].version;

  // check lastest version
  const isLastest = (version) => {
    return version === sortVersion[0].version;
  };
  return (
    <Row
      onClick={() => toViewScreen(item, isLastest(item.version))}
      style={{
        cursor: "pointer",
      }}
      className="itemSearch"
    >
      <Col span={24} className="flex-start">
        <Row style={{ width: "100%" }} align={"middle"}>
          <Col span={!isPC && 3}>
            <Avatar
              style={{
                marginRight: isPC ? 10 : 5,
                background: "#0080cb",
              }}
              src={item.createAvatarUrl}
            >
              {" "}
              {avatarChar}
            </Avatar>
          </Col>
          <Col span={isPC ? 23 : 21}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <p
                className="contentText display-1-line "
                style={
                  !isPC
                    ? {
                        marginTop: "0",
                        paddingTop: 0,
                        // width: "calc(100% - 190px)",
                      }
                    : {}
                }
              >
                {item.estimationNumber}
              </p>
              <p className="contentText display-1-line">{item.customerName}</p>
              <p className="contentText display-1-line">
                {item.constructionName}
              </p>

              <div
                style={{
                  marginTop: 5,
                }}
              >
                <Select
                  onSelect={(v) => {
                    toViewScreen(
                      {
                        id: item.id,
                        version: v,
                      },
                      isLastest(v)
                    );
                  }}
                  className="selectVersion"
                  defaultValue={currentVersion}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  popupMatchSelectWidth={false}
                  style={{ width: "190px" }}
                  options={optionVersion.map((item) => ({
                    value: item.key,
                    label: item.value,
                  }))}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ItemSearch;
