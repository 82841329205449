import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { EstimateApi } from "../../../api/backend/estimate";
import Loading from "../../../component/Desktop/loading/loading";
import { KEY } from "../../../constants/common.const";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import {
  closeLoading,
  closeSave,
  openLoading,
  resetState,
  setDataConstant,
  setDataStep1,
  setDataStep2,
  setDataStep3,
  setDetailData,
  setListLv,
  setOptionDefault,
} from "../../../redux/estimate";
import { getStringQuery } from "../../../utils/helpers";
import AddStep1 from "./step1";
import AddStep2 from "./step2";
import AddStep3 from "./step3";
const AddEstimate = () => {
  const { step, CloneId, version } = getQuerySearch();
  let stringQuery = getStringQuery({ CloneId, version });
  const location = useLocation();
  const { dataStep1, loading } = useSelector((state) => state.estimate);
  const { id: idEdit } = useParams();
  const id = idEdit || CloneId;
  const dispatch = useDispatch();
  const basePath = location.pathname + location.search;

  let ComponentAdd;
  switch (step) {
    case "1": {
      ComponentAdd = AddStep1;

      break;
    }
    case "2": {
      ComponentAdd = AddStep2;
      break;
    }
    case "3": {
      ComponentAdd = AddStep3;
      break;
    }
    default: {
      ComponentAdd = AddStep1;
    }
  }
  useEffect(() => {
    dispatch(closeSave());
    return () => {
      dispatch(resetState());
    };
  }, []);

  // getDataDetail
  useEffect(() => {
    if (id) dispatch(openLoading());
    Promise.all([
      id
        ? version
          ? EstimateApi.getDetailEstimate(id, version)
          : EstimateApi.getEstimateDetailLastVersion(id)
        : null,
      EstimateApi.getConstantDataStep3(),
      EstimateApi.getWater(),
      EstimateApi.getOptions(),
      EstimateApi.getLv(),
      EstimateApi.getWaterDetail(),
    ])
      .then((res) => {
        const listLv = res[4].data.data || [];
        const optionDefault = res[3].data.data
          .map((item, index) => ({
            ...item,
            key: index,
            checked: false,
            value: item.defaultValue,
          }))
          .slice(0, 6);

        if (id) {
          const optionEditScreen = res[0].data.options
            .map((item, index) => ({
              ...item,
              key: index,
              checked: true,
            }))
            .map((item) => {
              const { optionId } = item;
              const op = optionDefault.find((i) => i.optionId === optionId);
              if (op) {
                item.defaultValue = op.defaultValue;
              }
              return item;
            });
          const resDetailData = res[0].data;
          dispatch(setDetailData(resDetailData));
          const dataStep1 = {
            havePacInjectionDevice: resDetailData.havePacInjectionDevice,
            haveHypotoniaInjectionDevice:
              resDetailData.haveHypotoniaInjectionDevice,
            havePhAdjusterInjectionDevice:
              resDetailData.havePhAdjusterInjectionDevice,
            haveControlPanel: resDetailData.haveControlPanel,
            estimationNumber: resDetailData.estimationNumber,
            customerName: resDetailData.customerName,
            constructionName: resDetailData.constructionName,
            rawWaterType: resDetailData.rawWaterType,
            rawWaterDetailType: resDetailData.rawWaterDetailType,
            estimationConditionInput: resDetailData.estimationConditionInput,
            estimationAuxMachine: {
              ...resDetailData.estimationAuxMachine,
              auxBackwashPumpRegularNumber: 1,
              auxAirWashingBlowerRegularNumber: 1,
            },
            fiberLength: resDetailData.fiberLength,
            completeSpareMachineQuantity:
              resDetailData.completeSpareMachineQuantity,
            options: optionEditScreen,
          };
          const dataWater = res[2].data.data;
          const warterTypeValue = dataWater.find(
            (item) => item.key === dataStep1.rawWaterType
          );
          const warterTypeValueDetail = res[5].data.data.find(
            (item) => item.key === dataStep1.rawWaterDetailType
          );
          dataStep1.rawWaterTypevalue =
            warterTypeValue && warterTypeValueDetail
              ? warterTypeValue.value + " - " + warterTypeValueDetail.value
              : "";

          dispatch(setDataStep1(dataStep1));
          const lvItem = listLv.find(
            (item) =>
              item.rawWaterType == dataStep1.rawWaterType &&
              item.fiberLength == dataStep1.fiberLength
          );

          dispatch(
            setDataStep2({
              lv: {
                current: res[0].data.standardLv,
                upperLimit: lvItem && lvItem.upperLimit,
                lowerLimit: lvItem && lvItem.lowerLimit,
              },
              machineSelected: res[0].data.machineSelected,
            })
          );
        }

        const dataConstStep3 = res[1].data;
        dispatch(setDataConstant(dataConstStep3));
        const data3 = id ? res[0].data.estimationConst : res[1].data;
        dispatch(setDataStep3({ estimationConst: data3 }));

        dispatch(setListLv(listLv));
        dispatch(setOptionDefault(optionDefault));
      })
      .catch(() => showMessage(KEY.ERROR, MSG_CATCH()))
      .finally(() => dispatch(closeLoading()));
  }, [id]);
  return (
    <div>
      <Loading show={loading} />
      <ComponentAdd
        basePath={basePath}
        stringQuery={stringQuery}
        dataStep1={dataStep1}
      />
    </div>
  );
};
export default AddEstimate;
