import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { EstimateApi } from "../../../api/backend/estimate";
import Loading from "../../../component/Desktop/loading/loading";
import { KEY } from "../../../constants/common.const";
import {
  MSG_CATCH,
  getQuerySearch,
  showMessage,
} from "../../../constants/utils";
import { updateTitleHeaderWithoutConst } from "../../../redux/sideBar";
import ViewStep1 from "./step1";
import ViewStep2 from "./step2";
import ViewStep3 from "./step3";
import {
  closeLoading,
  openLoading,
  setDetailData,
} from "../../../redux/estimate";

const ViewEstimate = () => {
  const { step } = getQuerySearch();
  const location = useLocation();
  const { version } = getQuerySearch();
  const [dataDetail, setDataDetail] = useState(null);
  const basePath = location.pathname + location.search;
  const { id } = useParams();
  const { loading } = useSelector((state) => state.estimate);

  const dispatch = useDispatch();
  let ComponentView;
  let titlepage;
  const TITLE = {
    STEP1: "入力情報",
    STEP2: "選定結果",
    STEP3: "見積表の出力",
  };
  switch (step) {
    case "1": {
      ComponentView = ViewStep1;
      titlepage = TITLE.STEP1;
      break;
    }
    case "2": {
      ComponentView = ViewStep2;
      titlepage = TITLE.STEP2;
      break;
    }
    case "3": {
      ComponentView = ViewStep3;
      titlepage = TITLE.STEP3;
      break;
    }
    default: {
      titlepage = TITLE.STEP1;
      ComponentView = ViewStep1;
    }
  }
  const fetchDataDetails = async () => {
    try {
      dispatch(openLoading());
      const res = await EstimateApi.getDetailEstimate(id, version);
      setDataDetail(res.data);
      dispatch(setDetailData(res.data));
    } catch (error) {
      showMessage(KEY.ERROR, MSG_CATCH());
    } finally {
      dispatch(closeLoading());
    }
  };

  useEffect(() => {
    fetchDataDetails();
  }, []);
  useEffect(() => {
    dispatch(updateTitleHeaderWithoutConst(titlepage + " rev." + version));
  }, [titlepage, dispatch, step, version]);

  return (
    <div>
      <ComponentView dataDetail={dataDetail} basePath={basePath} />
      <Loading show={loading} />
    </div>
  );
};
export default ViewEstimate;
